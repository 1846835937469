/** 売上取込設定画面 */

import * as React from 'react';
import styled from 'styled-components';
import { Location } from 'history';
import { connect } from 'react-redux';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { Formik } from 'formik';

import Templates from '../../../components/common/templates';
import ApiError from '../../../components/common/templates/ApiError';
import AirRadioButton from '../../../components/common/molecules/Airkit/AirRadioButton';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import TitleHeader from '../../../components/common/TitleHeader';
import Toolbar from '../../../components/common/molecules/Toolbar';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import { genGaLog } from '../../../gaLogger';
import {
  gray,
  lightgray,
  uploadBorderColor,
  black,
  red,
  orangeBorder,
  orangeBg,
} from '../../../constants/colors';
import { toolbarHight } from '../../../constants/size';
import { dataImportSettingFaq } from '../../../constants/faqUrls';
import { State as ReduxState } from '../../../modules';
import { track } from '../../../modules/logging';
import { actions } from '../../../modules/dailySales';
import { showCommonDialog, hideCommonDialog } from '../../../modules/uiConfig';
import { assertUnreachable } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';
import {
  ApiState,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
} from '../../../typedef/api/Utility';
import { ConfigDailySalesList } from '../../../typedef/api/DailySales';
import { dailySalesStoreDataSelector } from '../../../selectors/dailySalesSelector';
import { StoresData } from '../../../modules/user';
import AlertOrange from '../../../icons/alertOrange.svg';

type StateProps = {
  readonly dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
  readonly postDailySalesState: ApiState<void>;
};

type DispatchProps = {
  readonly fetchDailySales: typeof actions.fetchDailySales;
  readonly postDailySales: typeof actions.postDailySales;
  readonly showCommonDialog: typeof showCommonDialog;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly track: typeof track;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

const Content = ({
  dailySalesStoreDataState,
  postDailySalesState,
  postDailySales,
  showConfirmDialog,
  showAlertDialog,
  tracking,
}: {
  dailySalesStoreDataState: ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>;
  postDailySalesState: ApiState<void>;
  postDailySales: typeof actions.postDailySales;
  showConfirmDialog: (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => void;
  showAlertDialog: (setFieldValue, idx) => void;
  tracking: typeof track;
}) => {
  switch (dailySalesStoreDataState.type) {
    case API_STATE_INITIAL:
    case API_STATE_STARTED:
      return (
        <Templates.Center>
          <ActivityIndicator />
        </Templates.Center>
      );
    case API_STATE_FAILED:
      return <ApiError />;
    case API_STATE_COMPLETED:
      return (
        <Formik
          initialValues={{ dailySales: dailySalesStoreDataState.payload }}
          onSubmit={values => {
            if (values.dailySales != null) {
              const postValues = values.dailySales.map(dailySale => ({
                akrCode: dailySale.akrCode,
                isSalesManualInput: dailySale.isSalesManualInput,
              }));
              postDailySales({ configDailySalesList: postValues });
              tracking(_genPostData());
            }
          }}
        >
          {({ values, dirty, handleSubmit, setFieldValue, resetForm }) => {
            return (
              <React.Fragment>
                <Prompt
                  when={dirty}
                  message={nextLocation => {
                    if (dirty) {
                      showConfirmDialog(resetForm, nextLocation);
                      return !dirty;
                    } else {
                      return true;
                    }
                  }}
                />
                <TableWrapper>
                  <Table>
                    <Th>
                      <Text>店舗名</Text>
                    </Th>
                    <Th>
                      <Text>売上取込方法</Text>
                    </Th>
                    {values.dailySales?.map((dailySale, idx) => {
                      return (
                        <React.Fragment key={`store_${idx}`}>
                          <Td>
                            <StoreName>
                              {dailySale.storeName}
                              {!dailySale.isRegiUse && <NotActiveAirRegi>Airレジ未連携</NotActiveAirRegi>}
                            </StoreName>
                          </Td>
                          <Td>
                            <StyledAirRadioButton
                              name={`airRegi${idx}`}
                              label="Airレジから自動連携"
                              value={dailySale.isSalesManualInput.toString()}
                              checked={dailySale.isSalesManualInput.toString() === 'false'}
                              onChange={
                                dailySale.isRegiUse
                                  ? () => setFieldValue(`dailySales[${idx}].isSalesManualInput`, false)
                                  : undefined
                              }
                              isDisabled={!dailySale.isRegiUse}
                            />

                            <StyledAirRadioButton
                              name={`dailyReport${idx}`}
                              label="日報から入力"
                              value={dailySale.isSalesManualInput.toString()}
                              checked={dailySale.isSalesManualInput.toString() === 'true'}
                              onChange={() => {
                                showAlertDialog(setFieldValue, idx);
                              }}
                            />
                          </Td>
                        </React.Fragment>
                      );
                    })}
                  </Table>
                </TableWrapper>
                <Toolbar align="right">
                  <Button
                    type="submit"
                    primary
                    disabled={!dirty || postDailySalesState.type === API_STATE_STARTED}
                    onClick={handleSubmit}
                  >
                    {postDailySalesState.type === API_STATE_STARTED ? '保存中' : '保存する'}
                  </Button>
                </Toolbar>
              </React.Fragment>
            );
          }}
        </Formik>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class DailySales extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchDailySales();
    this.props.track(_genDidMountLog());
  }

  _showConfirmDialog = (resetForm: () => void, nextLocation?: Location, moveFunction?: () => void) => {
    const { history, showCommonDialog, hideCommonDialog } = this.props;
    showCommonDialog({
      title: '保存されていません',
      message: 'このまま移動すると変更した内容は破棄されます。よろしいですか？',
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '移動する',
          onClick: () => {
            resetForm();
            hideCommonDialog();

            if (nextLocation != null && nextLocation.pathname != null) {
              setTimeout(() => {
                history.push(nextLocation.pathname);
              }, 0);
            }

            moveFunction != null && moveFunction();
          },
          primary: true,
        },
      ],
    });
  };

  _showAlertDialog = (setFieldValue, idx) => {
    const { showCommonDialog, hideCommonDialog, track } = this.props;
    track(_genAlertModal());
    showCommonDialog({
      title: '売上取込方法を変更してよろしいですか？',
      message: (
        <AlertTextWrapper>
          日報から売上の入力を行なった日は、Airレジの連携データがあっても
          <ForRed>日報から入力されたデータで上書きされ、上書き前の売上データは復元できません。</ForRed>
          <br />
          日報からの入力がない日は、Airレジから売上データが自動連携されます。
        </AlertTextWrapper>
      ),
      actions: [
        { text: '設定に戻る', onClick: hideCommonDialog },
        {
          text: '変更する',
          onClick: () => {
            hideCommonDialog();
            setFieldValue(`dailySales[${idx}].isSalesManualInput`, true);
          },
          primary: true,
        },
      ],
    });
  };

  render() {
    const { dailySalesStoreDataState, postDailySalesState, postDailySales, track } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <TitleHeader
            track={track}
            title="売上取込設定"
            faqTitle="設定方法"
            faqLink={dataImportSettingFaq}
            pageName="data_import_setting"
          />
          <Description>
            売上データの取込方法を変更することができます。
            <br />
            Airレジの売上データを自動で連携したい場合は、「Airレジから自動連携」を選択してください。
          </Description>
          <React.Fragment>
            <CommentWrapper>
              <CommentTitle>
                <StyledAlertOrange />
                Airレジ利用中の店舗が「日報から入力」を選択した場合
              </CommentTitle>
              <CommentText>
                Airレジから連携された売上・客数・商品などのデータが
                <ForRed>日報から入力したデータで上書きされ、上書き前のデータは復元できなくなります。</ForRed>
              </CommentText>
              <CommentText>日報からの入力がない日は、Airレジから売上データが自動連携されます。</CommentText>
            </CommentWrapper>
            <Content
              dailySalesStoreDataState={dailySalesStoreDataState}
              postDailySalesState={postDailySalesState}
              postDailySales={postDailySales}
              showConfirmDialog={this._showConfirmDialog}
              showAlertDialog={this._showAlertDialog}
              tracking={track}
            />
          </React.Fragment>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    dailySalesStoreDataState: dailySalesStoreDataSelector(state),
    postDailySalesState: state.dailySales.postDailySalesState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchDailySales: bindActionCreators(actions.fetchDailySales, dispatch),
    postDailySales: bindActionCreators(actions.postDailySales, dispatch),
    showCommonDialog: bindActionCreators(showCommonDialog, dispatch),
    hideCommonDialog: bindActionCreators(hideCommonDialog, dispatch),
    track: bindActionCreators(track, dispatch),
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px 24px;
  margin-bottom: ${toolbarHight}px;
  height: 100%;
`;

const Description = styled.div`
  padding: 8px 0 0 0;
  font-size: 14px;
`;

const CommentWrapper = styled.div`
  background: ${orangeBg};
  border: 1px solid ${orangeBorder};
  border-radius: 6px;
  margin: 24px 0;
  padding: 16px;
  font-size: 14px;
`;

const StyledAlertOrange = styled(AlertOrange)`
  margin-right: 5px;
`;

const CommentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${black};
`;

const CommentText = styled.div`
  font-size: 14px;
  color: ${black};
  margin-left: 20px;
  padding-left: 15px;
  padding-top: 4px;
  position: relative;
  &::before {
    content: '・';
    color: ${black};
    left: 0;
    position: absolute;
  }
`;

const ForRed = styled.span`
  color: ${red};
`;

const TableWrapper = styled.div`
  min-height: 300px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Th = styled.div`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  display: flex;
  justify-content: center;
  height: 51px;
  border: 1px solid ${lightgray};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Td = styled.div`
  border: 1px solid ${lightgray};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const StoreName = styled.div`
  width: 100%;
`;

const NotActiveAirRegi = styled.span`
  font-size: 12px;
  font-weight: 600;
  background: ${uploadBorderColor};
  color: white;
  padding: 2px 4px;
  margin-left: 6px;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-right: 24px;
`;

const AlertTextWrapper = styled.div`
  font-size: 14px;
  color: ${black};
`;

const _genDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'data_import_setting',
    'data_import_setting',
    'on_load',
    {},
    {},
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genPostData = () => {
  return genGaLog(
    'data_import_setting',
    'data_import_setting',
    'submit_sales_data_import_setting',
    {},
    {},
    'click'
  );
};

const _genAlertModal = () => {
  return genGaLog('data_import_setting', 'alert_modal', 'on_load', {}, {}, 'load');
};

export default connect(mapStateToProps, mapDispatchToProps)(DailySales);
