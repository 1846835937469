import { createSelector } from 'reselect';
import { assertUnreachable, joinStoreByAkrcode } from '../helpers/util';
import { userStoresDataStateSelector } from './userDataSelector';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { ConfigDailySalesList, FetchDailySalesResponse } from '../typedef/api/DailySales';
import {
  ApiState,
  apiState,
  API_STATE_COMPLETED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_FAILED,
} from '../typedef/api/Utility';
import { StoresData } from '../modules/user';
import { selectedStoreSelector } from './dailyReportListSelector';

export const dailySalesState: _InputSelector<ApiState<FetchDailySalesResponse | undefined>> = state =>
  state.dailySales.fetchDailySalesState;

const dailySalesData: _InputSelector<FetchDailySalesResponse | undefined> = state =>
  state.dailySales.fetchDailySalesState.type === API_STATE_COMPLETED
    ? state.dailySales.fetchDailySalesState.payload
    : undefined;

export const dailySalesDataJoinedStoreNameSelector: _OutputSelector<
  ReadonlyArray<StoresData & ConfigDailySalesList> | undefined
> = createSelector(userStoresDataStateSelector, dailySalesData, (userDataState, dailySalesData) => {
  if (dailySalesData != null && userDataState.type === API_STATE_COMPLETED) {
    let storeArray: Array<StoresData> = [];
    // joinStoreByAkrcodeのクラッシュ防止
    userDataState.payload.forEach(store => {
      for (let i = 0; i < dailySalesData.configDailySalesList.length; i++) {
        dailySalesData.configDailySalesList[i].akrCode === store.akrCode && storeArray.push(store);
      }
    });
    return joinStoreByAkrcode(dailySalesData.configDailySalesList, storeArray);
  } else {
    return undefined;
  }
});
// @ts-ignore
export const dailySalesStoreDataSelector: _OutputSelector<
  ApiState<ReadonlyArray<StoresData & ConfigDailySalesList> | undefined>
> = createSelector(
  userStoresDataStateSelector,
  dailySalesState,
  dailySalesDataJoinedStoreNameSelector,
  (userDataState, dailySalesState, joinedData) => {
    if (userDataState.type === API_STATE_INITIAL || dailySalesState.type === API_STATE_INITIAL) {
      return apiState.initial();
    } else if (userDataState.type === API_STATE_STARTED || dailySalesState.type === API_STATE_STARTED) {
      return apiState.started();
    } else if (userDataState.type === API_STATE_FAILED) {
      return apiState.failed(userDataState.error);
    } else if (dailySalesState.type === API_STATE_FAILED) {
      return apiState.failed(dailySalesState.error);
    } else if (dailySalesState.type === API_STATE_COMPLETED && userDataState.type === API_STATE_COMPLETED) {
      return apiState.completed(joinedData);
    }
    assertUnreachable();
    return apiState.initial();
  }
);

export const hasSalesManualInputStoreSelector: _OutputSelector<boolean | undefined> = createSelector(
  dailySalesData,
  dailySales => {
    return dailySales?.configDailySalesList.some(items => items.isSalesManualInput);
  }
);

export const getDailyReportSelectedStoreSalesManualInputSelector: _OutputSelector<boolean | undefined> =
  createSelector(dailySalesData, selectedStoreSelector, (dailySalesData, selectedStore) => {
    const dailySalesSelectedStore = dailySalesData?.configDailySalesList.find(
      dailySale => dailySale.akrCode === selectedStore?.akrCode
    );
    return dailySalesSelectedStore ? dailySalesSelectedStore.isSalesManualInput : undefined;
  });
