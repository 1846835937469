import { all, fork, put, select, take, call } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist'; // COMMENT: ?
import axios from 'axios';
import { State } from '../modules';
import { actions } from '../modules/launch';
import { fetchUserInfo, StoresData, types, UserData, postUserInfo } from '../modules/user';
import {
  setSelectedStore,
  userFetchStoresStart,
  fetchPresenceOrAbsenceOfSalesListStart,
  types as storesTypes,
} from '../modules/stores';
import { fetchAssignedStoresStart } from '../modules/assignedStores';
import { startBatchProccessedDate, startBatchStatus, types as uiConfigTypes } from '../modules/uiConfig';
import { getAPIURL } from '../helpers/stringHelper';
import { getEnv, pipe2 } from '../helpers/util';
import * as Optional from '../helpers/optional';
import * as Onboarding from '../helpers/onboardingHelper';
import { getMobileType } from '../helpers/util';
import { returnCodes } from '../constants/mapi';
import { mobileWidthThreshold } from '../constants/size';
import {
  airMateAppUrlFromRedirectAirMarket,
  airMateAndroidUrl,
  airMateAppUrlFromRedirectABT,
} from '../constants/externalLink';
import {
  BASIC_SETTING_ID,
  COST_SETTING_ID,
  DAILY_REPORT_ID,
  STORE_TARGET_ID,
  MONTHLY_COST_ID,
  ONBOARDING_GOAL_IDS,
  CLOSE_TUTORIAL_ALLINDEX,
} from '../constants/onboarding';
import {
  REDIRECT_URL_KEY_NAME,
  SELECTED_AKRCODE,
  ONB_DISABLE_INTRO_AUTO_DISPLAY,
  TUTORIAL_GOAL_STARTED_ID,
} from '../constants/LocalStorage';
import { history } from '../configureStore';
import { isGourmetStore } from '../typedef/StoreGenre';
import * as AkrCode from '../typedef/AkrCode';
import {
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../typedef/api/Utility';
import { PresenceOrAbsenceOfSalesListResponse, StoreList } from '../typedef/api/PresenceOrAbsenceOfSalesList';
import { fetchBadgeList } from '../modules/badge';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import { getCookie } from '../helpers/cookieHelper';
import { InfluxData } from '../typedef/CookieData';
import { validateUnlessHalfAlphanumericAndUnderScore } from '../helpers/validateHelper';
import { formatter, mclDayjs } from '../helpers/mclDate';

function* initializeSaga() {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; // loginしていないことを検知した場合はデフォルトでPLFに飛ばす。

  const createInfluxDataCookie = () => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const vos = params.get('vos');
    const lid = params.get('lid');
    const via_promo_flg = params.get('via_promo_flg');

    //流入元情報がアクセスURLに存在する場合はcookieを発行する
    if (vos != null || lid != null || via_promo_flg != null) {
      const cookieData = {
        vos: vos != null ? encodeURIComponent(vos) : vos,
        lid: lid != null ? encodeURIComponent(lid) : lid,
        via_promo_flg: via_promo_flg != null ? encodeURIComponent(via_promo_flg) : via_promo_flg,
      };
      var date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      const expires = '; expires=' + date.toUTCString();
      //有効期限1ヶ月でJSON形式で保存する
      window.document.cookie = 'influxData=' + JSON.stringify(cookieData) + expires + '; path=/';
    }
  };

  const getForward = (): string => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const target = params.get('target');

    if (target === 'register') {
      return 'https://connect.airregi.jp/view/signup/email-input?client_id=AMT&redirect_uri=https%3A%2F%2Fconnect.airregi.jp%2Foauth%2Fauthorize%3Fclient_id%3DAMT%26redirect_uri%3Dhttps%253A%252F%252Fmapi.airmate.jp%252Fauth%252Fcallback%26response_type%3Dcode&is_oauth=1';
    }
    return getAPIURL('auth/web/');
  };

  // iPhone, Androidの場合(iPadは除く)に、リダイレクトフラグがある場合にAppStoreへ遷移させる
  const redirectAppStoreIfNeeded = () => {
    const url = new URL(window.location.href);
    const redirectAppStore = url.searchParams.get('redirect_app_store');

    const appStoreLink = (): string | null => {
      if (redirectAppStore == null) return null;
      switch (getMobileType()) {
        case 'iphone':
          return makeAppleAppStoreLink(redirectAppStore);
        case 'android':
          return makeGooglePlayStoreLink(redirectAppStore);
        case 'other':
          return null;
      }
    };

    const makeAppleAppStoreLink = (type: string | null): string | null => {
      switch (type) {
        case 'air_market':
          return airMateAppUrlFromRedirectAirMarket;
        default:
          return null;
      }
    };

    const makeGooglePlayStoreLink = (type: string | null): string | null => {
      switch (type) {
        case 'air_market':
          return airMateAndroidUrl;
        default:
          return null;
      }
    };

    const isMobileWidth = window.innerWidth < mobileWidthThreshold;
    const link = appStoreLink();
    if (isMobileWidth && link != null) {
      window.location.href = link;
    }
  };

  redirectAppStoreIfNeeded();

  // URLクエリパラメータにリダイレクトフラグ(redirect_app_store_flg)が存在し、値が'true'の場合、動的にURLを生成しリダイレクトする
  // デバイスごとの処理↓
  // ・iPhone・Androidの場合、各AppStoreのリンクを生成し、リダイレクトする
  // ・PC / iPadの場合、リダイレクトはせずに後続処理へ
  // ※クエリパラメータctのバリデーションの結果がNGの場合、各ストアへリダイレクトする(iPhoneの場合はctを除いた状態のストアへリダイレクト)
  const redirectAppStoreHasRedirectFlag = () => {
    const url = new URL(window.location.href);
    const redirectAppStoreFlg = url.searchParams.get('redirect_app_store_flg');
    const ct = url.searchParams.get('ct');
    const isValidatedCt = ct != null ? validateUnlessHalfAlphanumericAndUnderScore(ct) : true;
    const appStoreLink = (): string | null => {
      if (redirectAppStoreFlg !== 'true') {
        return null;
      }
      switch (getMobileType()) {
        case 'iphone':
          return isValidatedCt ? airMateAppUrlFromRedirectABT : `${airMateAppUrlFromRedirectABT}&ct=${ct}`;
        case 'android':
          return airMateAndroidUrl;
        case 'other':
          return null;
      }
    };

    const isMobileWidth = window.innerWidth < mobileWidthThreshold;
    const link = appStoreLink();
    if (isMobileWidth && link != null) {
      window.location.href = link;
    }
  };

  redirectAppStoreHasRedirectFlag();

  axios.interceptors.response.use(undefined, err => {
    // Network Errorの場合responseはundefined
    if (!err.response) {
      window.location.href = getForward();
      return Promise.reject(err);
    } else {
      const returnCode = err.response.data.returnCode;
      if (err.response.status === 401) {
        switch (returnCode) {
          case returnCodes.sessionTimeout:
            //Cookieが有効な場合にCookieを利用する処理を呼び出すようにする(Cookie無効な場合にエラーにもならず処理が中断してしまう為)
            if (navigator.cookieEnabled) {
              createInfluxDataCookie();
              localStorage.setItem(REDIRECT_URL_KEY_NAME, window.location.pathname);
            }
            window.location.href = getForward();
            break;

          case returnCodes.invalidAccessToken:
            window.location.href = getAPIURL('logout');
            break;

          default:
            window.location.href = getAPIURL('logout');
            break;
        }
      }

      return Promise.reject(err.response);
    }
  });

  yield take(REHYDRATE);
  yield put(fetchUserInfo());
  yield put(fetchBadgeList());
  yield take(types.FETCH_USER_INFO_SUCCESS);
  const userData: UserData = yield select((state: State) => state.user.data);
  const userStores: ReadonlyArray<StoresData> = yield select((state: State) => assignedStoresSelector(state));
  if (userData.firstEventDate == null) {
    yield put(postUserInfo());
  }
  if (userStores.length !== 0) {
    const url = localStorage.getItem(REDIRECT_URL_KEY_NAME);
    if (url != null) {
      localStorage.removeItem(REDIRECT_URL_KEY_NAME);
      history.push(url);
    }
  }

  if (userStores.length === 0) {
    const path: string = history.location.pathname;

    if (path !== '/error') {
      history.push('/error');
    }

    yield put(actions.initialize());
  } else {
    yield all([
      put(userFetchStoresStart()),
      put(startBatchProccessedDate()),
      put(startBatchStatus()),
      put(fetchAssignedStoresStart()),
    ]);
    // TODO: エラーハンドリングでFailureのパターンを修正する
    yield all([uiConfigTypes.SUCCESS_FETCH_BATCH_PROCCESSED_DATE, uiConfigTypes.SUCCESS_FETCH_BATCH_STATUS]);
    let storeAkrCode: string | null = yield select((state: State) => {
      const { stores } = state;
      const selectedStore = userStores.find(() =>
        pipe2(
          stores.selectedAkrCode,
          Optional.flatMap(selectedAkrCode => userStores.find(store => store.akrCode === selectedAkrCode)),
          Optional.map(store => store.akrCode)
        )
      );
      return selectedStore != null ? selectedStore.akrCode : null;
    });

    if (storeAkrCode == null) {
      storeAkrCode = userStores[0].akrCode;
    }
    yield put(fetchPresenceOrAbsenceOfSalesListStart());
    yield take(storesTypes.FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END);
    yield call(onBoardingSaga);

    yield put(setSelectedStore(storeAkrCode));
    yield put(actions.initialize());
  }
}

function* onBoardingSaga() {
  const userData: UserData = yield select((state: State) => {
    const { user } = state;
    const data = user.data ? user.data : ({} as UserData);
    return data;
  });
  const assignedStores: ReadonlyArray<StoresData> = yield select((state: State) =>
    assignedStoresSelector(state)
  );

  const presenceOrAbsenceOfSalesList: PresenceOrAbsenceOfSalesListResponse = yield select((state: State) => {
    const { stores } = state;
    const presenceOrAbsenceOfSalesListState = stores.presenceOrAbsenceOfSalesListState;
    switch (presenceOrAbsenceOfSalesListState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
      case API_STATE_FAILED:
        return null;
      case API_STATE_COMPLETED:
        return presenceOrAbsenceOfSalesListState.payload;
    }
  });

  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    window.navigator.userAgentData != null ||
    (userAgent.indexOf('msie') === -1 && userAgent.indexOf('trident') === -1)
  ) {
    var ONB = ONB || {};
    ONB.ignition_url =
      'https://api.onboarding-app.io/v1/onboarding-init?aid=17&pid=32' +
      (getEnv() !== 'production' ? '&display_mode=preview' : '');

    // Custom Area Start=====================
    let akrCode;
    const localStorageSelectedAkrcode = localStorage.getItem(SELECTED_AKRCODE);
    if (localStorageSelectedAkrcode != null) {
      //ローカルストレージに存在する店舗情報がログインユーザーに紐づく店舗リストに存在する場合は、当該店舗の情報をオンスタに連携する
      assignedStores.forEach(store => {
        if (store.akrCode === localStorageSelectedAkrcode) {
          akrCode = AkrCode.of(localStorageSelectedAkrcode);
        }
      });
      if (akrCode == null) {
        akrCode = assignedStores[0].akrCode;
      }
    } else {
      // デフォルト店舗が取得出来る場合はAPIの店舗を、取得できない場合は店舗リストの1番目の店舗を選択
      akrCode =
        presenceOrAbsenceOfSalesList != null
          ? presenceOrAbsenceOfSalesList.presenceOrAbsenceOfSalesList.defaultStore
          : assignedStores[0].akrCode;
    }

    const storeData: StoresData | undefined = assignedStores.find(store => store.akrCode === akrCode);
    const presenceOrAbsenceOfSales: StoreList | undefined =
      presenceOrAbsenceOfSalesList != null
        ? presenceOrAbsenceOfSalesList.presenceOrAbsenceOfSalesList.storeList.find(
            store => store.akrCode === akrCode
          )
        : undefined;
    const numberAkrCode = Number.parseInt(akrCode.toString().substring(3));

    //スマホ判定する為の閾値のサイズ
    const thresholdWidth: number = 600;
    const thresholdHeight: number = 1024;

    // 初回アクセスフラグ: 初回ログイン日が当日かどうか判定
    const isFirstEvent: boolean =
      userData.firstEventDate == null || userData.firstEventDate === mclDayjs().format(formatter.mapiDate);

    // lid追加の為、cookieからinFluxData取得
    const influxDataCookie = getCookie('influxData');
    const influxData: InfluxData = influxDataCookie != null && JSON.parse(influxDataCookie);

    // groupIDのナンバーのみ
    const numberGroupId = Number(userData.groupId.substring(1));

    ONB._queryparam = {
      user_id: userData.clientUserId,
      user_name: userData.representName,
      user_group_id: userData.groupId,
      // userInfoで法人名はまだ取得できないので空指定
      user_group_name: '',
      has_gourmet_stores: assignedStores.some(store => isGourmetStore(store.genre)),
      is_shift_active: storeData?.isShiftActive,
      is_shift_use: storeData?.isShiftUse,
      is_handy_active: storeData?.isHandyActive,
      is_handy_use: storeData?.isHandyUse,
      is_rb_active: storeData?.isRbActive,
      is_rb_use: storeData?.isRbUse,
      is_regi_use: storeData?.isRegiUse,
      is_air_pay_use: storeData?.isAirPayUse,
      is_air_card_use: storeData?.isAirCardUse,
      is_sp_use: userData.isSpUse,
      is_infomart_group_use: userData.stores.some(store => store.isInfomartUse),
      is_this_month_sales:
        presenceOrAbsenceOfSales != null ? presenceOrAbsenceOfSales.isThisMonthSales : true,
      is_last_month_sales:
        presenceOrAbsenceOfSales != null ? presenceOrAbsenceOfSales.isLastMonthSales : true,
      is_first_event: isFirstEvent,
      screen_width: window.screen.width,
      screen_height: window.screen.height,
      browser_width: window.innerWidth,
      browser_height: window.innerHeight,
      mobile_screen: window.screen.width < thresholdWidth && window.screen.height < thresholdHeight,
      akrCode_divide_2: numberAkrCode % 2,
      akrCode_divide_3: numberAkrCode % 3,
      akrCode_divide_4: numberAkrCode % 4,
      group_id_divide_2: numberGroupId % 2,
      group_id_divide_3: numberGroupId % 3,
      group_id_divide_4: numberGroupId % 4,
      first_event_date: userData.firstEventDate,
      lid: influxData.lid,
    };

    ONB.black_list = [];

    /**
     * オンスタのready関数のコールバック関数
     * @param first_display_onboard 初めてのOnboarding起動かどうか
     */
    // @ts-ignore
    window.onBoardingInit = function (first_display_onboard: boolean) {
      // 未読バッジの更新
      const element = window.document.getElementById('tutorial_init');
      if (element != null) {
        element.click();
      }

      // ONB_DISABLE_INTRO_AUTO_DISPLAYは自動で利用ガイドや初回オンスタが表示させるかのkey。
      // 初回オンスタ：×で閉じられたらtrueで格納
      // 利用ガイド：「閉じる」ボタンか×で閉じられたらtrueで格納
      if (!first_display_onboard && !localStorage.getItem(ONB_DISABLE_INTRO_AUTO_DISPLAY)) {
        // ONB_DISABLE_INTRO_AUTO_DISPLAYは「自動表示するか否か」でデータがない場合で且つ初回表示ではない場にshowIntroする
        // 年始サマリではshowIntroさせない
        if (RegExp(/^(?!.*year_lookback).*$/).test(window.location.href)) {
          Onboarding.showIntro();
        }
      }
    };

    // @ts-ignore
    window.guideShow = function (id: string) {
      // やることリストに含まれているidは更新しない
      if (
        id === BASIC_SETTING_ID ||
        id === COST_SETTING_ID ||
        id === DAILY_REPORT_ID ||
        id === STORE_TARGET_ID ||
        id === MONTHLY_COST_ID
      ) {
        return;
      }
      // 未読バッジの更新
      localStorage.setItem(TUTORIAL_GOAL_STARTED_ID, id);
      const element = window.document.getElementById('tutorial_goal_started');
      if (element != null) {
        element.click();
      }
    };

    // @ts-ignore
    window.introDisplayed = function () {
      const allIndexElement: HTMLElement | null = document.querySelector('[data-goalid="airmate-listcheck"]');
      //単店舗の場合は全店舗のゴールを非表示
      if (allIndexElement !== null && assignedStores.length === 1) {
        allIndexElement.style.display = 'none';
      }
    };

    // @ts-ignore
    // オンスタ利用ガイドの進行中に途中で×ボタンで閉じた場合に、引数に閉じられたガイドのゴールIDが渡される。
    window.stepAborted = function (goalId) {
      switch (goalId) {
        case ONBOARDING_GOAL_IDS.allIndex:
          const elm = document.getElementById(CLOSE_TUTORIAL_ALLINDEX);
          elm != null && elm.click();
        default:
          break;
      }
    };

    // @ts-ignore
    // オンスタ利用ガイドの進行を完了させて閉じた場合に、引数に閉じられたガイドのゴールIDが渡される。
    window.goalCompleted = function (goalId) {
      switch (goalId) {
        case ONBOARDING_GOAL_IDS.allIndex:
          const elm = document.getElementById(CLOSE_TUTORIAL_ALLINDEX);
          elm != null && elm.click();
        default:
          break;
      }
    };

    ONB._custom_functions = {
      ready: 'onBoardingInit',
      goal_started: 'guideShow',
      intro_displayed: 'introDisplayed',
      step_aborted: 'stepAborted',
      goal_completed: 'goalCompleted',
    };
    // Custom Area End======================

    ONB.embed = function () {
      for (ONB.item in ONB._queryparam) {
        ONB.ignition_url += '&' + ONB.item + '=' + encodeURIComponent(ONB._queryparam[ONB.item]);
      }
      for (ONB.d = 0; ONB.d < ONB.black_list.length; ONB.d++) {
        if (window.location.href.indexOf(ONB.black_list[ONB.d]) !== -1) {
          return;
        }
      }
      if (Object.keys(ONB._custom_functions).length > 0) {
        ONB.ignition_url += '&custom_functions=' + encodeURIComponent(JSON.stringify(ONB._custom_functions));
      }
      ONB.b = document.createElement('script');
      ONB.c = document.getElementsByTagName('head')[0];
      ONB.b.src = ONB.ignition_url;
      ONB.b.id = 'stands_onbd_point';
      ONB.b.charset = 'utf-8';
      ONB.b.async = 'async';
      ONB.c.appendChild(ONB.b);
    };
    ONB.embed();
  }
}

export default function* launchSaga() {
  yield fork(initializeSaga);
}
