// 月次レポート
import { fork, all, take, select, call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { MonthlyLookbackAPI } from '../services/monthlyLookbackAPI';
import { State as RootState } from '../modules';
import { StoresData, FETCH_USER_INFO_SUCCESS } from '../modules/user';
import { types as batchTypes } from '../modules/uiConfig';
import { actions as uiActions, INITIAL_FETCH } from '../modules/monthlyLookback/ui';
import { actionTypes, actions } from '../modules/monthlyLookback/api';
import { MonthlyLookbackListResponse } from '../typedef/api/MonthlyLookback';
import { MonthlyLookbackDetailResponse } from '../typedef/api/MonthlyLookback';
import { ApiCallEffectResult } from '../typedef/api/Utility';
import { assignedStoresSelector } from '../selectors/userDataSelector';
import { BatchProcessedDate } from '../typedef/BatchProcessedDate';
import { formatter, parser } from '../helpers/mclDate';
import { getThirteenMonthsPeriod } from '../helpers/mclDateHelper';

export function* initialFetchMonthlyLookbackSaga() {
  while (true) {
    yield all([
      take(batchTypes.SUCCESS_FETCH_BATCH_PROCCESSED_DATE),
      take(FETCH_USER_INFO_SUCCESS),
      take(INITIAL_FETCH),
    ]);
    yield put(actions.initializeMonthlyLookbackDetail());
    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );
    const sortedStores: Array<StoresData> = _.sortBy(stores, ['storeName']);
    const akrCodes = sortedStores.map(store => store.akrCode);
    yield put(uiActions.selectStore(new Set(akrCodes)));

    const batchDate: BatchProcessedDate = yield select(
      (state: RootState) => state.uiConfig.batchProcessedDate
    );
    const monthList = getThirteenMonthsPeriod(batchDate);

    yield put(uiActions.setMonthList(monthList));
    yield put(uiActions.changePeriod(monthList[0]));
    yield call(executeFetchbackDetail);
  }
}

function* fetchMonthlyLookbackList() {
  yield takeLatest(actionTypes.FETCH_MONTHLY_LOOKBACK_LIST, function* () {
    yield call(executeFetchList);
  });
}

function* fetchMonthlyLookbackDetail() {
  yield takeLatest(actionTypes.FETCH_MONTHLY_LOOKBACK_DETAIL, function* () {
    yield call(executeFetchbackDetail);
  });
}

function* executeFetchList() {
  let akrCodes: Set<string> = yield select((state: RootState) => state.monthlyLookbackUi.selectedStores);

  const yearMonthText: string = yield select((state: RootState) =>
    parser.fromYearMonthObject(state.monthlyLookbackUi.yearMonth).format(formatter.mapiYearMonth)
  );

  const yearMonth = yield select((state: RootState) => state.monthlyLookbackUi.yearMonth);
  const summaryViewType = yield select((state: RootState) => state.monthlyLookbackUi.summaryViewType);

  if (akrCodes != null && akrCodes.size !== 0 && yearMonthText.length === 7) {
    yield put(uiActions.changeShouldShowSammary(true));
    yield put(uiActions.changeViewParameter(yearMonth, summaryViewType));
    const { payload, error }: ApiCallEffectResult<MonthlyLookbackListResponse> = yield call(
      MonthlyLookbackAPI.fetchMonthlyLookbackList,
      {
        akrCodes: Array.from(akrCodes),
        businessMonth: yearMonthText,
      }
    );

    if (error) {
      // @ts-ignore ErrorTypeとAxiosXHRの型エラー
      yield put(actions.fetchMonthlyLookbackListFailure(error));
    } else if (payload) yield put(actions.fetchMonthlyLookbackListSucces(payload));
  } else {
    window.location.href = '/monthly_lookback';
    yield put(uiActions.changeShouldShowSammary(false));
  }
}

function* executeFetchbackDetail() {
  const akrCodes: Set<string> = yield select((state: RootState) => state.monthlyLookbackUi.selectedStores);
  const yearMonthText: string = yield select((state: RootState) =>
    parser.fromYearMonthObject(state.monthlyLookbackUi.yearMonth).format(formatter.mapiYearMonth)
  );

  const yearMonth = yield select((state: RootState) => state.monthlyLookbackUi.yearMonth);
  const summaryViewType = yield select((state: RootState) => state.monthlyLookbackUi.summaryViewType);

  if (akrCodes != null && akrCodes.size !== 0 && yearMonthText != null) {
    yield put(uiActions.changeShouldShowThreshold(true));
    yield put(uiActions.changeViewParameter(yearMonth, summaryViewType));
    const { payload, error }: ApiCallEffectResult<MonthlyLookbackDetailResponse> = yield call(
      MonthlyLookbackAPI.fetchMonthlyLookbackDetail,
      {
        akrCodes: Array.from(akrCodes),
        businessMonth: yearMonthText,
      }
    );

    if (error) {
      // @ts-ignore ErrorTypeとAxiosXHRの型エラー
      yield put(actions.fetchMonthlyLookbackDetailFailure(error));
    } else if (payload) yield put(actions.fetchMonthlyLookbackDetailSucces(payload));
  } else {
    yield put(uiActions.changeShouldShowThreshold(false));
  }
}

export function* monthlyLookback() {
  yield all([
    fork(fetchMonthlyLookbackList),
    fork(initialFetchMonthlyLookbackSaga),
    fork(fetchMonthlyLookbackDetail),
  ]);
}
