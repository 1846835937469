import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ReloadBlue from '../../../../../icons/reloaded.svg';
import ClickOutside from 'react-click-outside';
import { hoverAndSelectedColor, uploadBorderColor, textLinkColor } from '../../../../../constants/colors';
import {
  baseFontSize,
  postfixUnit,
  Decimal,
  redGreen100,
} from '../../../../../components/common/atoms/Number';
import ZIndex from '../../../../../constants/z-index';
import * as AkrCode from '../../../../../typedef/AkrCode';

type Props = {
  readonly options: ReadonlyArray<{ storeName: string; akrCode: AkrCode.T; salesGoalRate?: number | string }>;
  readonly handleClickStoreListItem: (a: {
    readonly akrCode: AkrCode.T;
    readonly storeName?: string;
  }) => void;
  readonly selectedAkrCode?: AkrCode.T;
  readonly selectedStoreName?: string;
  readonly headerLeftWord?: string;
  readonly headerRightWord?: string;
  readonly isSelectedThisRange: boolean;
};

type State = {
  isOpenSelectBox: boolean;
  isSelected: boolean;
};

const FormatGoalSales = baseFontSize(14)(redGreen100(postfixUnit('%')(Decimal)));

class StorechangeButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenSelectBox: false,
      isSelected: false,
    };
  }

  _handleClickSelector(e: React.SyntheticEvent<HTMLElement>, akrCode: AkrCode.T) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.setState({
      ...this.state,
      isSelected: true,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
    const { options, handleClickStoreListItem } = this.props;
    const store = options.find(s => s.akrCode === akrCode);
    if (store != null) {
      handleClickStoreListItem(store);
    }
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    this.setState({
      ...this.state,
      isOpenSelectBox: !this.state.isOpenSelectBox,
    });
  };
  _renderListItem = (
    options: ReadonlyArray<{ storeName: string; akrCode: AkrCode.T; salesGoalRate?: number | string }>,
    akrCode?: AkrCode.T,
    storeName?: string
  ) => {
    const { headerLeftWord, headerRightWord, isSelectedThisRange } = this.props;
    return (
      <ClickOutside
        onClickOutside={e => {
          this._handleToggleSelectBox(e);
        }}
      >
        <Balloon>
          <BoxPointer />
          {/** 文字数によって吹き出しの位置調整する */}
          <List rightAligned={storeName != null ? storeName.length > 28 : false}>
            {isSelectedThisRange && (
              <ListHeader>
                <LeftWord>{headerLeftWord}</LeftWord>
                <RightWord>{headerRightWord}</RightWord>
              </ListHeader>
            )}
            {options.map(choice => (
              <ListItem
                key={choice.akrCode}
                isSelected={choice.akrCode === akrCode}
                onClick={e => this._handleClickSelector(e, choice.akrCode)}
              >
                <StoreName>{choice.storeName}</StoreName>
                {isSelectedThisRange && (
                  <div>
                    {choice.salesGoalRate != null && choice.salesGoalRate !== 0 ? (
                      <FormatGoalSales value={choice.salesGoalRate} />
                    ) : (
                      '-%'
                    )}
                  </div>
                )}
              </ListItem>
            ))}
          </List>
        </Balloon>
      </ClickOutside>
    );
  };
  render() {
    const { options, selectedAkrCode, selectedStoreName } = this.props;
    return (
      // 親にflexがきてもいいようにdivで囲っておく
      <div>
        <EditItem onClick={e => this._handleToggleSelectBox(e)} id="store_change_store_button">
          <StyledReloadBlue />
          店舗の変更
        </EditItem>
        {this.state.isOpenSelectBox && this._renderListItem(options, selectedAkrCode, selectedStoreName)}
      </div>
    );
  }
}

const StyledReloadBlue = styled(ReloadBlue)`
  margin-right: 6px;
`;

const EditItem = styled.span`
  display: flex;
  align-items: center;
  color: ${textLinkColor};
  font-size: 14px;
  cursor: pointer;
  width: 100px;
  margin-left: 16px;
`;

const List = styled.div<{ rightAligned: boolean }>`
  width: 340px;
  background-color: white;
  position: absolute;
  top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 350px;
  overflow-y: scroll;
  overflow-y: scroll;
  ${props => props.rightAligned && 'right: -180px;'}
`;
const ListHeader = styled.div`
  min-height: 38px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftWord = styled.div`
  font-size: 12px;
  font-weight: 600;
`;
const RightWord = styled.div`
  font-size: 11px;
`;
const ListItem = styled.div<{ isSelected: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && hoverAndSelectedColor};
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  word-break: break-all;
`;
const Balloon = styled.div`
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;
const BoxPointer = styled.div`
  left: 10px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: ${ZIndex.selectBox};
`;
const StoreName = styled.span`
  max-width: calc(100% - 30px);
`;

export default StorechangeButton;
