// 成績画面の月間成績タブのgenerator
import { fork, all, take, put, call, select, takeLatest } from 'redux-saga/effects';
import { State } from '../modules';
import {
  SelectStoreAction,
  types,
  actions,
  StartPostSalesCustomizeConfigAction,
  StartPostFiscalYearSettingsAction,
  StartFetchStoreSalesCustomizeItemAction,
  START_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
} from '../modules/stores';
import {
  types as IndicesSummaryActionTypes,
  actions as IndicesSummaryActions,
} from '../modules/storeIndices/storeIndicesSummary';
import {
  types as IndicesAnalysisActionTypes,
  actions as IndicesAnalysisActions,
} from '../modules/storeIndices/storeIndicesAnalysis';
import {
  FETCH_EXIST_MONTH_RESULT_LIST,
  FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START,
  FetchExistMonthResultList,
  FetchExistMonthResultListForRangeCheckAction,
  actions as existMonthResultListActions,
} from '../modules/storeIndices/existMonthResultList';
import {
  types as downloadStoreIndicesTypes,
  actions as downloadStoreIndicesActions,
} from '../modules/storeIndices/downloadStoreIndices';
import {
  FETCH_DAILY_SALES_DETAIL_START,
  FetchDailySalesDetailStartAction,
  actions as dailySalesDetailActions,
} from '../modules/storeIndices/dailySalesDetail';
import {
  FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START,
  FetchDailySeatOccupancyDetailStartAction,
  actions as dailySeatOccupancyDetailActions,
} from '../modules/storeIndices/dailySeatOccupancyDetail';
import {
  FETCH_DAILY_LATE_SERVE_DETAIL_START,
  FetchDailyLateServeDetailStartAction,
  actions as dailyLateServeDetailActions,
} from '../modules/storeIndices/dailyLateServeDetail';
import {
  FETCH_DAILY_LABOR_COST,
  FetchDailyLaborCostAction,
  actions as dailyLaborCostActions,
} from '../modules/storeIndices/dailyLaborCost';
import {
  FETCH_DAILY_SALES_PLAN_START,
  FetchDailySalesPlanStartAction,
  actions as dailySalesPlanActions,
} from '../modules/storeIndices/dailySalesPlan';
import LaborCostAPI from '../services/laborCostAPI';
import { FETCH_DAILY_DETAIL_START, FetchDailyDetailStartAction } from '../modules/storeIndices/dailyDetail';
import StoreIndicesAPI from '../services/storeIndicesAPI';
import { BudgetSettingAPI } from '../services/targetSettingAPI';
import * as Api from '../modules/targetSetting/api';
import SalesListAPI from '../services/salesListAPI';
import { ApiCallEffectResult, PostResponse } from '../typedef/api/Utility';
import {
  IndexAnalysis,
  ExistMonthResultList,
  SalesCustomizeItemList,
  PostSalesCustomizeRequest,
  DailySalesDetail,
  DailySeatOccupancyDetail,
  DailyLateServeDetail,
  DailySalesPlan,
  TaxSettingStatus,
} from '../typedef/api/StoreIndices';
import { PresenceOrAbsenceOfSalesListResponse } from '../typedef/api/PresenceOrAbsenceOfSalesList';
import { createGroupFiscalYearUpsertRequest, GroupFiscalYearResponse } from '../typedef/api/BudgetSetting';
import { actions as storeMonthlyActions } from '../modules/storeMonthlyIndices/api';
import { actions as ReserveDetailActions } from '../modules/realtime/reserveDetail';
import { actions as ReserveCourseSummaryActions } from '../modules/realtime/reserveCourseSummary';
import { actions as PreparationDetailActions } from '../modules/storeIndices/preparationDetail';
import { actions as ShiftsActions } from '../modules/realtime/shifts';
import * as AkrCode from '../typedef/AkrCode';
import * as Model from '../modules/targetSetting/model';
import * as GroupFiscalYearInfo from '../modules/targetSetting/model/groupFiscalYearInfo';
import { assertUnreachable, getErrorData } from '../helpers/util';
import { actions as basicSettingActions, types as basicSettingTypes } from '../modules/basicSetting';
import { LocalYearMonthObj, formatter, parser } from '../helpers/mclDate';

// 店舗選択時に必要なデータを取る
function* selectStoreSaga() {
  while (true) {
    const action: SelectStoreAction = yield take(types.SELECT_STORE);
    const newStoreAkr = action.payload;
    yield put(actions.setSelectedStore(newStoreAkr));

    yield put(IndicesSummaryActions.fetchStoreIndicesSummaryStart());
  }
}

function* fetchStoreIndicesSummarySaga() {
  yield takeLatest(IndicesSummaryActionTypes.FETCH_STORE_INDICES_SUMMARY_START, function* () {
    const selectedMonth: string | undefined = yield select(
      (state: State) => state.storeIndices.existMonthResultList.selectedMonth
    );
    const akr_code: string = yield select((state: State) => state.stores.selectedAkrCode);
    const { payload, error } = yield call(StoreIndicesAPI.fetchStoreIndicesSummary, akr_code, selectedMonth);

    if (payload != null && error == null) {
      yield put(IndicesSummaryActions.fetchStoreIndicesSummarySuccess(payload));
    } else {
      yield put(IndicesSummaryActions.fetchStoreIndicesSummaryFail(error));
    }
  });
}

function* fetchStoreIndicesAnalysisSaga() {
  yield takeLatest(IndicesAnalysisActionTypes.FETCH_STORE_INDICES_ANALYSIS_START, function* () {
    const akrCode: string | undefined | null = yield select((state: State) => state.stores.selectedAkrCode);
    const date: string | null = yield select(
      (state: State) => state.storeIndices.existMonthResultList.selectedMonth
    );

    if (date != null) {
      const { payload, error }: ApiCallEffectResult<IndexAnalysis> = yield call(
        StoreIndicesAPI.fetchStoreIndicesAnalysis,
        akrCode,
        date
      );

      if (error != null) {
        yield put(IndicesAnalysisActions.fetchStoreIndicesAnalysisFail(error));
      } else if (payload) {
        yield put(IndicesAnalysisActions.fetchStoreIndicesAnalysisSuccess(payload));
      }
    } else {
      // 月設定後呼び出しているので通常ありえないがdateがnullの場合
      return;
    }
  });
}

function* fetchStoreIndicesExistMonthResultListAndDailyAnalysisSaga() {
  yield takeLatest(FETCH_EXIST_MONTH_RESULT_LIST, (action: FetchExistMonthResultList) =>
    fetchExistMonthResultListData(action)
  );
}
function* fetchExistMonthResultListData(action: FetchExistMonthResultList) {
  const { payload, error }: ApiCallEffectResult<ExistMonthResultList> = yield call(
    StoreIndicesAPI.fetchStoreIndicesExistMonthResultList,
    action.payload
  );
  if (error != null) {
    yield put(existMonthResultListActions.fetchExistMonthResultListFailure(error));
  } else if (payload != null) {
    yield put(existMonthResultListActions.fetchExistMonthResultListSuccess(payload));
    yield put(IndicesAnalysisActions.fetchStoreIndicesAnalysisStart());
  }
}

// 月変更時に全てをリセットする
function* fetchStoreIndicesAllDataSaga() {
  while (true) {
    yield take(IndicesSummaryActionTypes.FETCH_STORE_INDICES_ALL_DATA);
    const selectedDate: string | null = yield select(
      state => state.storeIndices.existMonthResultList.selectedMonth
    );
    // TODO: selectedDateがnullならどうするか
    if (selectedDate == null) {
      return;
    }
    yield all([
      put(IndicesSummaryActions.resetStoreIndices()),
      put(IndicesAnalysisActions.resetStoreIndicesAnalysis()),
      put(IndicesSummaryActions.fetchStoreIndicesSummaryStart()),
      put(IndicesAnalysisActions.fetchStoreIndicesAnalysisStart()),
    ]);
  }
}

function* fetchStoreSalesCustomizeConfigSaga() {
  yield takeLatest(
    START_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
    (action: StartFetchStoreSalesCustomizeItemAction) => fetchStoreSalesCustomizeConfig(action)
  );
}

function* fetchStoreSalesCustomizeConfig(action: StartFetchStoreSalesCustomizeItemAction) {
  const { payload, error }: ApiCallEffectResult<SalesCustomizeItemList> = yield call(
    StoreIndicesAPI.fetchStoreSalesCustomizeItem,
    action.payload
  );

  if (error != null) {
    yield put(actions.failStoreSalesCustomizeItem(error));
  } else if (payload) {
    yield put(actions.successStoreSalesCustomizeItem(payload));
  }
}

function* fetchSalesCustomizeConfig() {
  yield takeLatest(types.START_FETCH_SALES_CUSTOMIZE_ITEM, function* () {
    const { payload, error }: ApiCallEffectResult<SalesCustomizeItemList> = yield call(
      StoreIndicesAPI.fetchSalesCustomizeItem
    );

    if (error != null) {
      yield put(actions.failSalesCustomizeItem(error));
    } else if (payload) {
      yield put(actions.successSalesCustomizeItem(payload));
    }
  });
}

function* postSalesCustomizeConfig() {
  while (true) {
    // takeEveryの方がいいかもしれないが、連続クリック対応していないのでこのまま
    const action: StartPostSalesCustomizeConfigAction = yield take(types.START_POST_SALES_CUSTOMIZE_CONFIG);
    const values = action.payload;

    const { payload, error }: ApiCallEffectResult<PostSalesCustomizeRequest> = yield call(
      StoreIndicesAPI.postSalesCustomizeConfig,
      {
        salesCustomizeItem: values,
      }
    );

    if (error) {
      yield put(actions.failurePostSalesCustomizeConfig(error));
    } else if (payload) {
      yield put(actions.successPostSalesCustomizeConfig());
    }
  }
}

function* reloadSalesCustomizeConfig() {
  while (true) {
    yield take(types.SUCCESS_POST_SALES_CUSTOMIZE_CONFIG);
    yield put(actions.startFetchSalesCustomizeItem());
    const akrCode: string | undefined | null = yield select((state: State) => state.stores.selectedAkrCode);
    if (akrCode != null) {
      yield put(actions.startFetchStoreSalesCustomizeItem(AkrCode.of(akrCode)));
    }
    yield put(actions.resetSalesCustomizeConfigModalState());
  }
}

function* postFiscalYear() {
  while (true) {
    // takeEveryの方がいいかもしれないが、連続クリック対応していないのでこのまま
    const action: StartPostFiscalYearSettingsAction = yield take(types.START_POST_FISCAL_YEAR_SETTINGS);
    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      BudgetSettingAPI.postGroupFiscalYear,
      createGroupFiscalYearUpsertRequest(action.payload)
    );

    if (error) {
      yield put(Api.actions.postGroupFiscalYearFailure(error));
      yield put(actions.closeFiscalYearSettingsMoal());
    } else if (payload) {
      const selectedIndicesType: string = yield select((state: State) => state.stores.selectedIndicesType);
      yield put(actions.closeFiscalYearSettingsMoal());

      if (selectedIndicesType === 'monthly') {
        const selectedYear: number = yield select((state: State) => state.storeMonthlyIndices.ui.selectYear);
        const akrCode: string = yield select((state: State) => state.stores.selectedAkrCode);
        yield put(
          storeMonthlyActions.fetchYearAnalysis({ akrCode: AkrCode.of(akrCode), fiscalYear: selectedYear })
        );
      }
      yield put(Model.sagaActions.updateGroupFiscalYearInfo(GroupFiscalYearInfo.create(action.payload)));
      yield put(actions.startFetchGroupFiscalYear());
      // 年度開始月更新時は基本設定の情報をリセットする
      yield put(basicSettingActions.resetBasicSetting());
    }
  }
}

function* fetchGroupFiscalYear() {
  yield takeLatest(types.START_FETCH_GROUP_FISCAL_YEAR, function* () {
    const { payload, error }: ApiCallEffectResult<GroupFiscalYearResponse> = yield call(
      BudgetSettingAPI.fetchGroupFiscalYear
    );
    if (error != null) {
      yield put(actions.failFetchGroupFiscalYear(error));
    } else if (payload) {
      yield put(actions.successFetchGroupFiscalYear(payload));
    }
  });
}

function* downloadStoreIndices() {
  while (true) {
    yield take(downloadStoreIndicesTypes.START_DOWNLOAD_SOTRE_INDICES);
    const akrCodes: ReadonlyArray<string> = yield select(
      (state: State) => state.storeIndices.download.akrCodes
    );
    const dateFrom: LocalYearMonthObj | undefined | null = yield select(
      (state: State) => state.storeIndices.download.dateFrom
    );
    const dateTo: LocalYearMonthObj | undefined | null = yield select(
      (state: State) => state.storeIndices.download.dateTo
    );

    if (dateFrom == null || dateTo == null) {
      continue;
    }

    const { payload, error }: ApiCallEffectResult<{ isSuccess: boolean }> = yield call(
      StoreIndicesAPI.downloadStoreIndices,
      {
        akrCodes,
        dateFrom: parser.fromYearMonthObject(dateFrom).format(formatter.mapiYearMonth),
        dateTo: parser.fromYearMonthObject(dateTo).format(formatter.mapiYearMonth),
      }
    );

    if (error) {
      const errorData = getErrorData(error);

      if (errorData != null && errorData instanceof Blob) {
        const data = yield call(blobToJson, errorData);

        if (data.returnCode === '6012') {
          yield put(downloadStoreIndicesActions.failureDownloadStoreIndices(undefined, true));
        } else {
          yield put(
            downloadStoreIndicesActions.failureDownloadStoreIndices(
              data.message != null
                ? data.message
                : '時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。'
            )
          );
        }
      } else {
        yield put(
          downloadStoreIndicesActions.failureDownloadStoreIndices(
            '時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。'
          )
        );
      }
    } else if (payload != null && payload.isSuccess) {
      yield put(downloadStoreIndicesActions.successDownloadStoreIndices());
    } else {
      // thenにもcatchにも入っていないので到達不可
      assertUnreachable();
    }
  }
}

const blobToJson = (blob: Blob): Promise<{}> => {
  const fileReader = new FileReader();
  return new Promise(resolve => {
    fileReader.onload = () => {
      // @ts-ignore
      resolve(JSON.parse(fileReader.result));
    };

    fileReader.readAsText(blob);
  });
};

function* fetchDailySalesDetailSaga() {
  yield takeLatest(FETCH_DAILY_SALES_DETAIL_START, (action: FetchDailySalesDetailStartAction) =>
    fetchDailySalesDetail(action)
  );
}

function* fetchDailySalesDetail(action: FetchDailySalesDetailStartAction) {
  const { payload, error }: ApiCallEffectResult<DailySalesDetail> = yield call(
    StoreIndicesAPI.fetchDailySalesDetail,
    action.payload
  );
  if (error != null) {
    yield put(dailySalesDetailActions.fetchDailySalesDetailFail(error));
  } else if (payload != null) {
    yield put(dailySalesDetailActions.fetchDailySalesDetailSuccess(payload));
  }
}

function* fetachDailySeatOccupancyDetailSaga() {
  yield takeLatest(
    FETCH_DAILY_SEAT_OCCUPANCY_DETAIL_START,
    (action: FetchDailySeatOccupancyDetailStartAction) => fetachDailySeatOccupancyDetail(action)
  );
}

function* fetachDailySeatOccupancyDetail(action: FetchDailySeatOccupancyDetailStartAction) {
  const { payload, error }: ApiCallEffectResult<DailySeatOccupancyDetail> = yield call(
    StoreIndicesAPI.fetchDailySeatOccupancyDetail,
    action.payload
  );
  if (error != null) {
    yield put(dailySeatOccupancyDetailActions.fetchDailySeatOccupancyDetailFail(error));
  } else if (payload != null) {
    yield put(dailySeatOccupancyDetailActions.fetchDailySeatOccupancyDetailSuccess(payload));
  }
}

function* fetchDailyLateServeDetailSaga() {
  yield takeLatest(FETCH_DAILY_LATE_SERVE_DETAIL_START, (action: FetchDailyLateServeDetailStartAction) =>
    fetchDailyLateServeDetail(action)
  );
}

function* fetchDailyLateServeDetail(action: FetchDailyLateServeDetailStartAction) {
  const { payload, error }: ApiCallEffectResult<DailyLateServeDetail> = yield call(
    StoreIndicesAPI.fetchDailyLateServeDetail,
    action.payload
  );
  if (error != null) {
    yield put(dailyLateServeDetailActions.fetchDailyLateServeDetailFail(error));
  } else if (payload != null) {
    yield put(dailyLateServeDetailActions.fetchDailyLateServeDetailSuccess(payload));
  }
}

function* fetchDailyLaborCostSaga() {
  yield takeLatest(FETCH_DAILY_LABOR_COST, (action: FetchDailyLaborCostAction) =>
    fetchDailyLaborCost(action)
  );
}

function* fetchDailyLaborCost(action: FetchDailyLaborCostAction) {
  const { payload, error } = yield call(LaborCostAPI.fetchDailyLaborCost, action.payload);
  if (error != null) {
    yield put(dailyLaborCostActions.failureDailyLaborCost(error));
  } else if (payload != null) {
    yield put(dailyLaborCostActions.successDailyLaborCost(payload));
  }
}

function* fetchDailyDetail(action: FetchDailyDetailStartAction) {
  const storesData = action.payload.storesData;
  const akrCode = storesData.akrCode;
  const isHandyUse = storesData.isHandyUse;
  const isShiftUse = storesData.isShiftUse;
  const isRbUse = storesData.isRbUse;
  const isRegiUse = storesData.isRegiUse;
  if (action.payload.isFuture) {
    yield all([
      put(dailySalesPlanActions.fetchDailySalesPlanStart(akrCode, action.payload.date)),
      isRbUse
        ? put(ReserveDetailActions.startFetchRealtimeReserveDetailData(akrCode, action.payload.date))
        : null,
      isRbUse
        ? put(
            ReserveCourseSummaryActions.startFetchRealtimeReserveCourseSummaryData(
              akrCode,
              action.payload.date
            )
          )
        : null,
      isShiftUse
        ? put(PreparationDetailActions.fetchPreparationDetailStart(akrCode, action.payload.date))
        : null,
      isShiftUse ? put(ShiftsActions.startFetchRealtimeShiftsData(akrCode, action.payload.date)) : null,
    ]);
  } else {
    yield all([
      isRegiUse
        ? put(dailySalesDetailActions.fetchDailySalesDetailStart(akrCode, action.payload.date))
        : null,
      isRbUse
        ? put(
            dailySeatOccupancyDetailActions.fetchDailySeatOccupancyDetailStart(akrCode, action.payload.date)
          )
        : null,
      isHandyUse
        ? put(dailyLateServeDetailActions.fetchDailyLateServeDetailStart(akrCode, action.payload.date))
        : null,
      isShiftUse ? put(dailyLaborCostActions.fetchDailyLaborCost(akrCode, action.payload.date)) : null,
    ]);
  }
}

function* fetchDailyDetailSaga() {
  yield takeLatest(FETCH_DAILY_DETAIL_START, (action: FetchDailyDetailStartAction) =>
    fetchDailyDetail(action)
  );
}

function* fetchExistMonthResultListForRangeCheckSaga() {
  yield takeLatest(
    FETCH_EXIST_MONTH_RESULT_LIST_FOR_RANGE_CHECK_START,
    (action: FetchExistMonthResultListForRangeCheckAction) => fetchExistMonthResultListForRangeCheck(action)
  );
}

function* fetchExistMonthResultListForRangeCheck(action: FetchExistMonthResultListForRangeCheckAction) {
  let existMonthResultList;
  for (let i = 0; i < action.payload.years.length; ++i) {
    const { payload, error }: ApiCallEffectResult<ExistMonthResultList> = yield call(
      StoreIndicesAPI.fetchStoreIndicesExistMonthResultList,
      { akrCode: action.payload.akrCode, year: action.payload.years[i] }
    );

    if (existMonthResultList == null && payload != null) {
      existMonthResultList = payload;
    } else {
      if (payload != null) {
        const tempExistMonthList: ReadonlyArray<{ yearMonth: string; isExist: boolean }> =
          existMonthResultList.existMonthList;
        existMonthResultList = payload;
        existMonthResultList.existMonthList = existMonthResultList.existMonthList.concat(tempExistMonthList);
      }
    }

    if (error != null) {
      yield put(existMonthResultListActions.fetchExistMonthResultListFailureForRangeCheck(error));
    } else if (payload != null) {
      yield put(
        existMonthResultListActions.fetchExistMonthResultListSuccessForRangeCheck(existMonthResultList)
      );
    }
  }
}

function* fetchDailySalesPlanSaga() {
  yield takeLatest(FETCH_DAILY_SALES_PLAN_START, (action: FetchDailySalesPlanStartAction) =>
    fetchDailySalesPlan(action)
  );
}

function* fetchDailySalesPlan(action: FetchDailySalesPlanStartAction) {
  const { payload, error }: ApiCallEffectResult<DailySalesPlan> = yield call(
    StoreIndicesAPI.fetchDailySalesPlan,
    action.payload
  );
  if (error != null) {
    yield put(dailySalesPlanActions.fetchDailySalesPlanFail(error));
  } else if (payload != null) {
    yield put(dailySalesPlanActions.fetchDailySalesPlanSuccess(payload));
  }
}

function* fetchresenceOrAbsenceOfSalesList() {
  yield takeLatest(types.FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START, function* () {
    const { payload, error }: ApiCallEffectResult<PresenceOrAbsenceOfSalesListResponse> = yield call(
      SalesListAPI.fetchresenceOrAbsenceOfSalesList
    );

    if (error != null) {
      yield put(actions.fetchPresenceOrAbsenceOfSalesListFail(error));
    } else if (payload) {
      yield put(actions.fetchPresenceOrAbsenceOfSalesListSuccess(payload));
    }
    yield put(actions.fetchPresenceOrAbsenceOfSalesListEnd());
  });
}

function* fetchTaxSettingStatus() {
  while (true) {
    yield all([
      take(types.FETCH_TAX_SETTING_STATUS_START),
      take(basicSettingTypes.FETCH_BASIC_SETTING_SUCCESS),
    ]);
    const { payload, error }: ApiCallEffectResult<TaxSettingStatus> = yield call(
      StoreIndicesAPI.fetchTaxSettingStatus
    );

    if (error != null) {
      yield put(actions.fetchTaxSettingStatusFail(error));
    } else if (payload) {
      yield put(actions.fetchTaxSettingStatusSuccess(payload));
    }
  }
}

export default function* storeIndicesSaga() {
  yield all([
    fork(selectStoreSaga),
    fork(fetchStoreIndicesSummarySaga),
    fork(fetchStoreIndicesAnalysisSaga),
    fork(fetchStoreIndicesAllDataSaga),
    fork(fetchStoreIndicesExistMonthResultListAndDailyAnalysisSaga),
    fork(fetchStoreSalesCustomizeConfigSaga),
    fork(fetchSalesCustomizeConfig),
    fork(postSalesCustomizeConfig),
    fork(reloadSalesCustomizeConfig),
    fork(postFiscalYear),
    fork(fetchGroupFiscalYear),
    fork(downloadStoreIndices),
    fork(fetchDailySalesDetailSaga),
    fork(fetachDailySeatOccupancyDetailSaga),
    fork(fetchDailyLateServeDetailSaga),
    fork(fetchDailyDetailSaga),
    fork(fetchDailyLaborCostSaga),
    fork(fetchExistMonthResultListForRangeCheckSaga),
    fork(fetchDailySalesPlanSaga),
    fork(fetchresenceOrAbsenceOfSalesList),
    fork(fetchTaxSettingStatus),
  ]);
}
