import axios, { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { getAPIURL } from '../helpers/stringHelper';
import { getCsvFileName } from '../helpers/util';
import { MapiResponse, ApiPromise, ErrorType, PostResponse } from '../typedef/api/Utility';
import {
  IndexSummary,
  IndexAnalysis,
  ExistMonthResultList,
  SalesCustomizeItemList,
  PostSalesCustomizeRequest,
  DownloadStoreIndicesRequest,
  DailySalesDetail,
  DailyLateServeDetail,
  DailySeatOccupancyDetail,
  DailySalesPlan,
  TaxSettingStatus,
} from '../typedef/api/StoreIndices';
import * as AkrCode from '../typedef/AkrCode';
/**
 * その店舗のある月のデータを取ってくる。dateが指定されていなければ最新月を取ってくる
 * @param date date.year, date.monthに年と月が格納されている
 */

const StoreIndicesAPI = {
  // date: YYYY-MM
  fetchStoreIndicesSummary(akrCode: string, date?: string): ApiPromise<IndexSummary> {
    return axios
      .get(getAPIURL('core/v3/stores/sales/monthly_analysis/'), {
        params: {
          akrCode,
          date,
        },
      })
      .then((res: AxiosResponse<MapiResponse<{ monthlyAnalysis: IndexSummary }>>) => {
        return {
          payload: res.data.result.monthlyAnalysis,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchStoreIndicesAnalysis(akrCode: string | undefined | null, date: string): ApiPromise<IndexAnalysis> {
    return axios
      .get(getAPIURL('core/v5/stores/sales/daily_analysis'), {
        params: {
          akrCode,
          date: date,
        },
      })
      .then((res: AxiosResponse<MapiResponse<{ dailyAnalysis: IndexAnalysis }>>) => {
        return {
          payload: res.data.result.dailyAnalysis,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchStoreIndicesExistMonthResultList(req: {
    akrCode: AkrCode.T;
    year: number;
  }): ApiPromise<ExistMonthResultList> {
    return axios
      .get(getAPIURL('core/v1/stores/sales/exist_of_month_result_list'), { params: req })
      .then((res: AxiosResponse<MapiResponse<ExistMonthResultList>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchStoreSalesCustomizeItem(req: { akrCode: AkrCode.T }): ApiPromise<SalesCustomizeItemList> {
    const url = getAPIURL('core/v1/stores/sales/store_customize_item_list');
    return axios
      .get(url, { params: req })
      .then((res: AxiosResponse<MapiResponse<SalesCustomizeItemList>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchSalesCustomizeItem(): ApiPromise<SalesCustomizeItemList> {
    const url = getAPIURL('core/v1/stores/sales/get_customize_item_list');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<SalesCustomizeItemList>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postSalesCustomizeConfig(value: PostSalesCustomizeRequest): ApiPromise<PostResponse> {
    const url = getAPIURL('core/v1/stores/sales/set_customize_item_list/');
    return axios
      .post(url, value)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  downloadStoreIndices(req: DownloadStoreIndicesRequest): ApiPromise<{ isSuccess: boolean }> {
    const url = getAPIURL('core/v1/stores/sales/daily_analysis_output');
    return axios
      .post(url, req, {
        responseType: 'blob',
      })
      .then((res: AxiosResponse<Blob>) => {
        if (!(res.request instanceof XMLHttpRequest)) {
          throw new Error('時間をおいて再送信してください。何度も失敗する場合はお問い合わせください。');
        } else {
          const fileName = getCsvFileName(res.request, 'airmate_rawdata_seiseki.csv');
          FileSaver.saveAs(res.data, fileName);
          return { payload: { isSuccess: true } };
        }
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  //date: yyyy-mm-dd
  fetchDailySalesDetail(req: { akrCode: AkrCode.T; date: string }): ApiPromise<DailySalesDetail> {
    return axios
      .get(getAPIURL('core/v1/stores/daily_sales_detail'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<DailySalesDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  //date: yyyy-mm-dd
  fetchDailySeatOccupancyDetail(req: {
    akrCode: AkrCode.T;
    date: string;
  }): ApiPromise<DailySeatOccupancyDetail> {
    return axios
      .get(getAPIURL('core/v1/stores/daily_seat_occupancy_detail'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<DailySeatOccupancyDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  //date: yyyy-mm-dd
  fetchDailyLateServeDetail(req: { akrCode: AkrCode.T; date: string }): ApiPromise<DailyLateServeDetail> {
    return axios
      .get(getAPIURL('core/v1/stores/daily_late_serve_detail'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<DailyLateServeDetail>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  //date: yyyy-mm-dd
  fetchDailySalesPlan(req: { akrCode: AkrCode.T; date: string }): ApiPromise<DailySalesPlan> {
    return axios
      .get(getAPIURL('core/v1/stores/daily_sales_plan'), {
        params: req,
      })
      .then((res: AxiosResponse<MapiResponse<DailySalesPlan>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
  fetchTaxSettingStatus(): ApiPromise<TaxSettingStatus> {
    return axios
      .get(getAPIURL('core/v1/stores/banner/tax_setting_status'))
      .then((res: AxiosResponse<MapiResponse<TaxSettingStatus>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default StoreIndicesAPI;
