import { setTk, setTkTimeStamp, setSessionId } from './modules/logging';
import { v1 as uuidv1 } from 'uuid';
import ReactGA from 'react-ga4';
import { Logger } from './typedef/logger';
import { formatter, mclDayjs } from './helpers/mclDate';

const env = process.env.REACT_APP_ENV;
let loggingEndPoint: string;

if (env === 'local' || env === 'development' || env === 'local-dev') {
  loggingEndPoint = 'G-YE38KDS4ZB';
} else if (env === 'staging') {
  loggingEndPoint = 'G-LSMGHDYF4W';
} else if (env === 'production') {
  loggingEndPoint = 'G-K5M0WXHSQF';
} else {
  loggingEndPoint = 'G-YE38KDS4ZB';
}

ReactGA.initialize(loggingEndPoint);
const APP_NAME = 'pc-web';

export const gaLogger = (store: any) => (next: any) => (action: any) => {
  const _setup = storeState => {
    const state = storeState.getState();
    const sendTimeDiff = mclDayjs().diff(state.logging.tkTimeStamp, 'minute');
    return {
      alabLogVer: '1.0.4',
      alabTimestamp: mclDayjs(),
      pk: uuidv1(),
      tk: state.logging.tk ? state.logging.tk : '-',
      alabDevice: state.logging.ua,
      alabClientUserId: state.user.data.clientUserId,
      alabAkrCode: state.stores.selectedAkrCode,
      lastSendTime: state.logging.tkTimeStamp,
      alabSessionFirstEvent: sendTimeDiff > 60 ? true : false,
      alabSessionId:
        sendTimeDiff > 60 ? uuidv1() : state.logging.sessionId === '' ? uuidv1() : state.logging.sessionId,
    };
  };

  const metaData = action.meta;

  if (!metaData || !metaData.gaLogging) {
    next(action);
    return;
  }

  const shouldSendGaData = metaData.gaLogging;

  const setupedData = _setup(store);

  const viewName = shouldSendGaData.feature;
  ReactGA.ga('set', 'page', viewName);
  const sendData = {
    event_category: 'custom_event',
    dimension1: setupedData.alabLogVer,
    dimension2: setupedData.pk,
    dimension3: setupedData.tk,
    dimension4: setupedData.alabTimestamp.format(formatter.dateTimeMilliSecond),
    dimension5: setupedData.alabDevice,
    dimension6: APP_NAME,
    dimension7: setupedData.alabClientUserId,
    dimension8:
      shouldSendGaData.akr_code != null
        ? shouldSendGaData.akr_code
        : _isShouldIncludeAkrCode(shouldSendGaData.viewName)
        ? setupedData.alabAkrCode
        : '',
    dimension9: setupedData.alabSessionFirstEvent,
    dimension10: setupedData.alabSessionId,
    dimension11: shouldSendGaData.viewName,
    dimension12: shouldSendGaData.feature,
    dimension13: shouldSendGaData.function,
    dimension14: shouldSendGaData.event,
    dimension15: JSON.stringify(shouldSendGaData.description),
    dimension16: JSON.stringify(shouldSendGaData.parameters),
    dimension17: shouldSendGaData.vos != null ? shouldSendGaData.vos.replaceAll('"', '') : undefined,
    dimension18: shouldSendGaData.lid != null ? shouldSendGaData.lid.replaceAll('"', '') : undefined,
    dimension19:
      shouldSendGaData.via_promo_flg != null ? shouldSendGaData.via_promo_flg.replaceAll('"', '') : undefined,
  };

  ReactGA.event(shouldSendGaData.event, sendData);
  store.dispatch(setTk(setupedData.pk));
  store.dispatch(setTkTimeStamp(setupedData.alabTimestamp));
  store.dispatch(setSessionId(setupedData.alabSessionId));

  if (env === 'local' || env === 'development' || env === 'local-dev') {
    console.log('🍣', shouldSendGaData);
    console.log('🍰', { ...setupedData, ...shouldSendGaData });
  }

  next(action);
  return;
}; // akrcodeを含めるログかどうかをviewnameを元に判断する関数

const _isShouldIncludeAkrCode = viewName => {
  // 必要に応じて_つなぎのパターンも追加する
  const shouldIncludeAkrCodeView = [
    'store',
    'store/indices',
    'store/summary',
    'index/detail',
    'store/customer',
    'store/operation',
    'store/qsc',
    'fl_input',
  ];
  return shouldIncludeAkrCodeView.includes(viewName);
};

export const genGaLog = (
  viewName: string,
  feature: string,
  funcName: string,
  description: object,
  parameters: object,
  event: string,
  akrCode?: string,
  vos?: string,
  lid?: string,
  viaPromoFlg?: string
): Logger => {
  return {
    tracker: {
      gaLogging: {
        viewName: viewName,
        feature: feature,
        function: funcName,
        description: description,
        parameters: parameters,
        event: event,
        akr_code: akrCode,
        vos: vos,
        lid: lid,
        via_promo_flg: viaPromoFlg,
      },
    },
  };
};
export const genComponentDidMountLog = (
  viewName: string,
  parameters: object,
  akrCode?: string,
  vos?: string,
  lid?: string,
  via_promo_flg?: string
) => {
  return genGaLog(viewName, viewName, 'on_load', {}, parameters, 'load', akrCode, vos, lid, via_promo_flg);
};
export const genValidationErrorLog = (viewName: string, input: object | string, type?: string) => {
  const validatedInput = {
    errorType: type,
    value: input,
  };
  return genGaLog(viewName, viewName, 'validation_error', {}, validatedInput, 'change');
};

export const genValidationErrorNoValueLog = (viewName: string, type?: string) => {
  const validatedInput = {
    errorType: type,
  };
  return genGaLog(viewName, viewName, 'validation_error', {}, validatedInput, 'change');
};

const getLocation = () => {
  switch (window.location.pathname) {
    case '/store':
    case '/store/':
      return 'store';

    case '/all_index':
    case '/all_index/':
      return 'all_index';

    case '/realtime':
    case '/realtime/':
      return 'realtime';

    case '/daily_report_list':
    case '/daily_report_list/':
      return 'daily_report_list';

    case '/set_target':
    case '/set_target/':
      return 'set_target';

    case '/daily_cost_list':
    case '/daily_cost_list/':
      return 'daily_cost_list';

    case '/menu_analysis/':
    case '/menu_analysis':
      return 'menu_analysis';

    case '/labor_cost_analysis/':
    case '/labor_cost_analysis':
      return 'labor_cost_analysis';

    case '/voucher_analysis/':
    case '/voucher_analysis':
      return 'voucher_analysis';

    case '/data_import_setting/':
    case '/data_import_setting':
      return 'data_import_setting';

    case '/past_data_import/':
    case '/past_data_import':
      return 'past_data_import';

    case '/data_output/':
    case '/data_output':
      return 'data_output';

    case '/monthly_lookback/':
    case '/monthly_lookback':
      return 'setting_monthly_lookback';

    case '/monthly_lookback_preview/':
    case '/monthly_lookback_preview':
      return 'monthly_lookback_preview';

    case '/monthly_lookback_print/':
    case '/monthly_lookback_print':
      return 'monthly_lookback_print';

    case '/basic_setting/':
    case '/basic_setting':
      return 'basic_setting';

    case '/target/':
    case '/target':
      return 'target';

    case '/cost_item_setting/':
    case '/cost_item_setting':
      return 'cost_item_setting';

    case '/customize_daily_report/':
    case '/customize_daily_report':
      return 'customize_daily_report';

    case '/cost_prediction_setting/':
    case '/cost_prediction_setting':
      return 'cost_prediction_setting';

    case '/store/indices/':
    case '/store/indices':
      return 'store_indices';

    case '/input_daily_report/':
    case '/input_daily_report':
      return 'input_daily_report';

    case '/card_cost_list/':
    case '/card_cost_list':
      return 'aircard_cost_management';

    case '/card_setting/':
    case '/card_setting':
      return 'aircard_linkage_settings';

    case '/product_idea_list/':
    case '/product_idea_list':
      return 'product_idea_list';

    case '/transfer/':
    case '/transfer':
      return 'transfer';

    case '/invoice_cost_list/':
    case '/invoice_cost_list':
      return 'invoice_cost_list';

    default:
      return '不明';
  }
};

export const genSideBarLogger = (title: string, functionName?: string, eventName?: string): Logger => {
  return {
    tracker: {
      gaLogging: {
        viewName: getLocation(),
        feature: 'sidebar',
        function: functionName ? functionName : 'transition',
        description: title && {
          link_to: title,
        },
        parameters: '',
        event: eventName != null ? eventName : 'click',
      },
    },
  };
};
