import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DropZone from 'react-dropzone';
import { Checkbox } from '@air-kit/air-kit';
import Dialog from '../../../components/common/molecules/Airkit/AirDialog';
import TitleHeader from '../../../components/common/TitleHeader';
import Step from '../../../components/common/atoms/Step';
import ActivityIndicator from '../../../components/common/ActivityIndicatorStatic';
import { lightgray, airblue, hoverAndSelectedColor, red, textLinkColor } from '../../../constants/colors';
import { State as ReduxState } from '../../../modules';
import { clickSidenavItemTracking } from '../../../modules/tracking';
import { track } from '../../../modules/logging';
import { pastDataImportFaq } from '../../../constants/faqUrls';
import { StoresData } from '../../../modules/user';
import { actions } from '../../../modules/pastDataImport';
import { eqs } from '../../../helpers/util';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { genGaLog } from '../../../gaLogger';
import ArrowLeft from '../../../icons/ArrowLeft.svg';
import ArrowRight from '../../../icons/ArrowRight.svg';
import DocumentIcon from '../../../icons/document.png';
import CalendarIcon from '../../../icons/calendarIcon.svg';
import CheckCircle from '../../../icons/checkCircle.png';
import { getCookie } from '../../../helpers/cookieHelper';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { LocalDateObj, mclDayjs, parser } from '../../../helpers/mclDate';
// eslint-disable-next-line import/no-restricted-paths
import moment from 'moment';
import Zindex from '../../../constants/z-index';

type DispatchProps = {
  changeDateFrom: typeof actions.changeDateFrom;
  changeDateTo: typeof actions.changeDateTo;
  selectStore: typeof actions.selectStore;
  selectAllStore: typeof actions.selectAllStore;
  unselectAllStore: typeof actions.unselectAllStore;
  startDownloadCsv: typeof actions.startDownloadCsv;
  setUploadFile: typeof actions.setUploadFile;
  startUploadCsv: typeof actions.startUploadCsv;
  setModalMessage: typeof actions.setModalMessage;
  resetModalMessage: typeof actions.resetModalMessage;
  readonly track: typeof track;
  readonly clickSidenavItemTracking: typeof clickSidenavItemTracking;
};

type StateProps = {
  readonly batchProcessedDate: BatchProcessedDate;
  readonly stores: ReadonlyArray<StoresData>;
  readonly dateFrom: LocalDateObj;
  readonly dateTo: LocalDateObj;
  readonly selectedStores: Array<string>;
  readonly uploadFileName?: string;
  readonly error?: {
    title: string;
    message: string;
  };
  readonly downloading: boolean;
  readonly uploading: boolean;
  readonly uploaded: boolean;
};

type State = {
  fromFocused: boolean;
  toFocused: boolean;
};

type Props = {} & DispatchProps & StateProps;

class PastDataImport extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fromFocused: false,
      toFocused: false,
    };
  }

  componentDidMount() {
    const { batchProcessedDate } = this.props;
    const batchFirstDate: LocalDateObj = {
      year: batchProcessedDate.year,
      month: batchProcessedDate.month,
      day: 1,
    };
    this.props.changeDateFrom(parser.fromDateObject(batchFirstDate).add(-12, 'month'));
    this.props.changeDateTo(parser.fromDateObject(batchFirstDate).add(-1, 'day'));
    const cookieData = getCookie('influxData');
    let vos: string | undefined;
    let lid: string | undefined;
    let viaPromoFlg: string | undefined;
    if (cookieData != null) {
      const cookieDataJson = JSON.parse(cookieData);
      vos = cookieDataJson.vos;
      lid = cookieDataJson.lid;
      viaPromoFlg = cookieDataJson.via_promo_flg;
    }

    const log = genGaLog(
      'past_data_import',
      'past_data_import',
      'on_load',
      {},
      {},
      'load',
      undefined,
      vos,
      lid,
      viaPromoFlg
    );
    this.props.track(log);
  }

  validateDateFromRange = (date: moment.Moment): boolean => {
    const { batchProcessedDate, dateTo } = this.props;
    const convertedDate = mclDayjs(date.toISOString());
    const localDate = convertedDate.toLocalDateObj();
    const batchDate = parser.fromDateObject(batchProcessedDate).add(1, 'day');

    if (parser.fromDateObject(localDate).isAfter(batchDate)) {
      // 未来は選択不可能にする
      return true;
    }

    const isBetweenInclusive = parser
      .fromDateObject(dateTo)
      .isBetween(parser.fromDateObject(localDate), batchDate, 'date', '[]');
    return !isBetweenInclusive;
  };

  validateDateToRange = (date: moment.Moment | null) => {
    if (date != null) {
      const { batchProcessedDate, dateFrom } = this.props;
      const convertedDate = mclDayjs(date.toISOString());
      const localDate = convertedDate.toLocalDateObj();
      const batchDate = parser.fromDateObject(batchProcessedDate).add(1, 'day');

      const isBetweenInclusive = parser
        .fromDateObject(localDate)
        .isBetween(parser.fromDateObject(dateFrom), batchDate, 'date', '[]');

      return !isBetweenInclusive;
    } else {
      return true;
    }
  };

  _handleChangeDateFrom = (date: moment.Moment | null) => {
    if (date != null) {
      this.props.changeDateFrom(mclDayjs(date.toISOString()));
    }
  };

  _handleChangeDateTo = (date: moment.Moment | null) => {
    if (date != null) {
      this.props.changeDateTo(mclDayjs(date.toISOString()));
    }
  };

  _handleSelectAllStore = () => {
    this.props.selectAllStore(this.props.stores.map(stores => stores.akrCode));
  };

  _handleUnselectAllStore = () => {
    this.props.unselectAllStore();
  };

  _handleSelectStore = (akrCode: string) => {
    this.props.selectStore(akrCode);
  };

  _handleClickDownload = (type: 'sales' | 'cost') => {
    if (eqs(type, 'sales')) {
      const log = genGaLog(
        'past_data_import',
        'download_csv',
        'download_sales_csv',
        {},
        {
          dateFrom: this.props.dateFrom,
          dateTo: this.props.dateTo,
        },
        'click'
      );
      this.props.track(log);
    }

    this.props.startDownloadCsv(type);
  };

  _handleDropFile = (acceptFile: Array<File>, rejectFile: Array<File>, event: React.SyntheticEvent) => {
    if (rejectFile.length > 0) {
      if (rejectFile.length > 1) {
        this.props.setModalMessage(
          'データのアップロードに失敗しました',
          'ファイルが複数ある場合は、1ファイルずつアップロードしてください。'
        );
      } else if (rejectFile[0].type !== 'text/csv') {
        this.props.setModalMessage(
          'データのアップロードに失敗しました',
          `ファイルの拡張子が「.csv」ではありません。
アップロードするファイルのデータ形式をご確認ください。`
        );
      }
    } else {
      let log;

      if (eqs(event.type, 'drop')) {
        log = genGaLog('past_data_import', 'upload_csv', 'select_upload_csv', {}, {}, 'drop');
      } else {
        log = genGaLog('past_data_import', 'upload_csv', 'select_upload_csv', {}, {}, 'click');
      }

      this.props.track(log);
      const { setUploadFile } = this.props;
      setUploadFile(acceptFile[0]);
    }
  };

  _handleClickUpload = event => {
    event.stopPropagation();
    const log = genGaLog('past_data_import', 'upload_csv', 'execute_upload_csv', {}, {}, 'click');
    this.props.track(log);
    this.props.startUploadCsv();
  };

  _handleClickResetModal = () => {
    this.props.resetModalMessage();
  };

  render() {
    const {
      track,
      stores,
      dateFrom,
      dateTo,
      selectedStores,
      uploadFileName,
      error,
      downloading,
      uploading,
      uploaded,
    } = this.props;
    return (
      <React.Fragment>
        {(downloading || uploading) && (
          <ActivityIndicator downloading={downloading} uploading={uploading} isBgWhite={true} />
        )}
        <Wrapper>
          <TitleHeader
            track={track}
            title="売上一括取込"
            faqTitle="売上一括取込の使い方"
            faqLink={pastDataImportFaq}
            pageName="past_data_import"
          />
          <Comment>
            売上・客数のデータをまとめてアップロードすることができます。また、過去の売上データをアップロードすることで、目標設定時の前年比較などに利用することができます。
          </Comment>
          <MainWrapper>
            <div>
              <Step title="1. 取込フォーマットダウンロード">
                <React.Fragment>
                  <AreaText>
                    ファイルをダウンロードして、売上のデータを入力してください。※データが月単位になっている場合は、1日に月の合計額を入力してください。
                  </AreaText>
                  {/* isDatePickerはZ-index調整用のフラグ、エラーモーダルが表示されていない場合のみtrueにする */}
                  <SubTitileAreaWrapper id="past_data_import_date_select" isDatePicker={error == null}>
                    <SubTitle>対象期間</SubTitle>
                    <SelectDateWrapper>
                      <CustomSingleDatePicker>
                        <SingleDatePicker
                          readOnly
                          date={moment(parser.fromDateObject(dateFrom).toISOString())}
                          numberOfMonths={1}
                          onDateChange={this._handleChangeDateFrom}
                          focused={this.state.fromFocused}
                          onFocusChange={({ focused }) =>
                            this.setState({
                              fromFocused: focused,
                            })
                          }
                          id="fromDate"
                          customInputIcon={<CalendarIcon />}
                          isOutsideRange={this.validateDateFromRange}
                          monthFormat="YYYY年M月"
                          displayFormat="YYYY/M/D"
                          hideKeyboardShortcutsPanel={true}
                          transitionDuration={0}
                          navPrev={
                            <ArrowLeftArea>
                              <ArrowLeft />
                            </ArrowLeftArea>
                          }
                          navNext={
                            <ArrowRightArea>
                              <ArrowRight />
                            </ArrowRightArea>
                          }
                        />
                      </CustomSingleDatePicker>
                      <DateText>〜</DateText>
                      <CustomSingleDatePicker>
                        <SingleDatePicker
                          readOnly
                          date={moment(parser.fromDateObject(dateTo).toISOString())}
                          numberOfMonths={1}
                          onDateChange={this._handleChangeDateTo}
                          focused={this.state.toFocused}
                          onFocusChange={({ focused }) =>
                            this.setState({
                              toFocused: focused,
                            })
                          }
                          id="toDate"
                          customInputIcon={<CalendarIcon />}
                          isOutsideRange={this.validateDateToRange}
                          monthFormat="YYYY年M月"
                          displayFormat="YYYY/M/D"
                          hideKeyboardShortcutsPanel={true}
                          transitionDuration={0}
                          navPrev={
                            <ArrowLeftArea>
                              <ArrowLeft />
                            </ArrowLeftArea>
                          }
                          navNext={
                            <ArrowRightArea>
                              <ArrowRight />
                            </ArrowRightArea>
                          }
                        />
                      </CustomSingleDatePicker>
                    </SelectDateWrapper>
                  </SubTitileAreaWrapper>
                  <SubTitileAreaWrapper id="past_data_import_store_select" isDatePicker={false}>
                    <SubTitleWrapper>
                      <SubTitle>対象店舗</SubTitle>
                      <div>
                        <LinkText onClick={this._handleSelectAllStore}>すべて選択</LinkText>
                        <LinkText onClick={this._handleUnselectAllStore}>すべて解除</LinkText>
                      </div>
                    </SubTitleWrapper>
                    <StoreList>
                      {stores.map(store => (
                        <StoreItem key={store.akrCode} onClick={() => this._handleSelectStore(store.akrCode)}>
                          <StyledCheckbox
                            // @ts-ignore airkit
                            id={`checkbox_${store.akrCode}`}
                            isChecked={selectedStores.includes(store.akrCode)}
                            onClick={e => e.stopPropagation()}
                          />
                          <CheckBoxLabel htmlFor={`checkbox_${store.akrCode}`}>
                            {store.storeName}
                          </CheckBoxLabel>
                        </StoreItem>
                      ))}
                    </StoreList>
                  </SubTitileAreaWrapper>
                  <SubTitileAreaWrapper id="past_data_import_csv_download_button" isDatePicker={false}>
                    <SubTitle>取込フォーマットCSV</SubTitle>
                    <DownloadButton
                      disabled={selectedStores.length === 0}
                      onClick={() => this._handleClickDownload('sales')}
                    >
                      ダウンロードする
                    </DownloadButton>
                    <CautionText hide={selectedStores.length !== 0}>
                      店舗を選択するとダウンロードできます
                    </CautionText>
                  </SubTitileAreaWrapper>
                  {/* <SubTitileAreaWrapper>
                      <SubTitle>コストデータ</SubTitle>
                      <DownloadButton
                        disabled={selectedStores.length === 0}
                        onClick={() => this._handleClickDownload('cost')}
                      >
                        ファイルをダウンロードする
                      </DownloadButton>
                      <CautionText disabled={selectedStores.length === 0}>
                        店舗を選択するとダウンロードできます
                      </CautionText>
                      </SubTitileAreaWrapper> */}
                </React.Fragment>
              </Step>
            </div>

            <div id="past_data_import_csv_upload_wrapper">
              <Step title="2. CSVファイルアップロード">
                <React.Fragment>
                  <AreaText>
                    売上データを入力したファイルをアップロードしてください（csv形式のみ）。取り込んだデータは一部翌日に反映されます。
                  </AreaText>
                  <DropArea
                    accept="application/vnd.ms-excel, text/csv"
                    multiple={false}
                    onDrop={this._handleDropFile}
                  >
                    {uploadFileName == null ? (
                      <FileImage src={DocumentIcon} alt="document" />
                    ) : uploaded ? (
                      <React.Fragment>
                        <FileImage src={CheckCircle} alt="OK" />
                        <FileText>
                          <p>アップロードが完了しました</p>
                          <p>
                            ファイル名：
                            {uploadFileName}
                          </p>
                        </FileText>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <FileImage src={DocumentIcon} alt="document" />
                        <FileText>{uploadFileName}</FileText>
                      </React.Fragment>
                    )}
                    <DescriptionWrapper>
                      <Description>ここにファイルをドラック&amp;ドロップ</Description>
                      <Description>
                        もしくは
                        <FileLink>ファイルを選択する</FileLink>
                      </Description>
                    </DescriptionWrapper>
                    <UploadButton
                      disabled={uploadFileName == null || uploaded}
                      onClick={event => this._handleClickUpload(event)}
                      id="past_data_import_csv_upload_button"
                    >
                      アップロードする
                    </UploadButton>
                  </DropArea>
                </React.Fragment>
              </Step>
            </div>
          </MainWrapper>
        </Wrapper>
        {error != null && (
          <Dialog title={error.title} onClose={() => this._handleClickResetModal()}>
            {error.message}
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  batchProcessedDate: state.uiConfig.batchProcessedDate,
  stores: assignedStoresSelector(state),
  dateFrom: state.pastDataImport.dateFrom,
  dateTo: state.pastDataImport.dateTo,
  selectedStores: state.pastDataImport.selectedStores,
  uploadFileName: state.pastDataImport.uploadFileName,
  error: state.pastDataImport.error,
  downloading: state.pastDataImport.downloading,
  uploading: state.pastDataImport.uploading,
  uploaded: state.pastDataImport.uploaded,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  changeDateFrom: bindActionCreators(actions.changeDateFrom, dispatch),
  changeDateTo: bindActionCreators(actions.changeDateTo, dispatch),
  selectStore: bindActionCreators(actions.selectStore, dispatch),
  selectAllStore: bindActionCreators(actions.selectAllStore, dispatch),
  unselectAllStore: bindActionCreators(actions.unselectAllStore, dispatch),
  startDownloadCsv: bindActionCreators(actions.startDownloadCsv, dispatch),
  setUploadFile: bindActionCreators(actions.setUploadFile, dispatch),
  startUploadCsv: bindActionCreators(actions.startUploadCsv, dispatch),
  setModalMessage: bindActionCreators(actions.setModalMessage, dispatch),
  resetModalMessage: bindActionCreators(actions.resetModalMessage, dispatch),
  track: bindActionCreators(track, dispatch),
  clickSidenavItemTracking: bindActionCreators(clickSidenavItemTracking, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PastDataImport);

const Wrapper = styled.div`
  min-width: 700px;
  padding: 16px 24px;
`;

const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
`;

const Comment = styled.p`
  margin-top: 16px;
`;

const AreaText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
`;

const DropArea = styled(DropZone)`
  padding: 94px 39px 57px;
  border: dashed 1px #cccccc;
  cursor: pointer;
`;

const SelectDateWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const SubTitileAreaWrapper = styled.div<{ isDatePicker: boolean }>`
  ${props =>
    props.isDatePicker &&
    `
  position: relative;
  z-index: ${Zindex.datePickerCalendar};
`}
  &:not(:last-child) {
    margin-bottom: 28px;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.span`
  display: inline-block;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
`;

const DateText = styled.p`
  margin: 10px;
  font-size: 14px;
  color: #999999;
  flex-shrink: 0;
  align-self: center;
`;

const CautionText = styled.p<{ hide: boolean }>`
  ${props => (props.hide ? 'display:none;' : '')} margin-top: 8px;
  font-size: 10px;
  color: ${red};
  text-align: center;
`;

const FileText = styled.p`
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
`;

const LinkText = styled.span`
  color: ${textLinkColor};
  font-size: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  margin-left: 12px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 84px;
`;

const Description = styled.p`
  color: #999999;
  text-align: center;
`;

const FileLink = styled.a`
  color: ${textLinkColor};
  cursor: pointer;
`;

const BaseButton = styled.button`
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  ${props => (props.disabled ? 'opacity: 0.3;' : 'cursor: pointer;')};
`;

const DownloadButton = styled(BaseButton)`
  width: calc(100% - 112px);
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 4%;
  background: #fafafa;
  box-shadow: 0 1px 0 0 rgba(199, 199, 199, 0.5);
  color: #777777;
  margin: 0 56px;
`;

const UploadButton = styled(BaseButton)`
  margin: 0 auto;
  margin-top: 18px;
  height: 44px;
  padding: 0 58px;
  background: ${airblue};
  box-shadow: 0 1px 0 0 rgba(25, 135, 179, 0.5);
  color: #ffffff;
  white-space: nowrap;
`;

const CustomSingleDatePicker = styled.div`
  max-width: 184px;
`;

const FileImage = styled.img`
  display: block;
  margin: 0 auto;
`;

const StoreList = styled.div`
  overflow-y: scroll;
  max-height: 200px;
  border: solid 1px ${lightgray};
`;

const StoreItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  height: auto;
  border: solid 1px ${lightgray};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
  > * {
    word-break: break-word;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  height: 18px;
  margin-right: 4px;
`;

const CheckBoxLabel = styled.label`
  font-weight: normal;
`;

const ArrowArea = styled.div`
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
`;

const ArrowLeftArea = styled(ArrowArea)`
  left: 22px;
`;

const ArrowRightArea = styled(ArrowArea)`
  right: 22px;
`;
