import * as React from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import Template from '../../../../components/common/templates';
import ActivityIndicatorMatekun from '../../../../components/common/ActivityIndicatorMatekun';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../../components/common/templates/ErrorCommon';
import { ApiState, apiState, API_STATE_COMPLETED } from '../../../../typedef/api/Utility';
import { MonthlyLookbackListResponse, ProductAnalysis } from '../../../../typedef/api/MonthlyLookback';
import {
  DetailList,
  DetailedSalesAnalysis,
  DetailedSalesAnalysisPage,
  MenuAnalysis,
} from '../../../../typedef/api/MonthlyLookback';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import * as SummaryViewType from '../../../../modules/monthlyLookback/summaryViewType';
import { actions as UiActions } from '../../../../modules/monthlyLookback/ui';
import { getStoreName } from '../../../../helpers/util';
import { returnCodes } from '../../../../constants/mapi';
import { black, hoverAndSelectedColor } from '../../../../constants/colors';
import { LABOR_COST_ANALYSIS_APPEAL_CLOSE_INFO_DATE_KEY_NAME } from '../../../../constants/LocalStorage';
import {
  FormatTenThousand,
  baseFontSize,
  mediumUnit,
  postfix,
} from '../../../../components/common/atoms/Number';
import ArrowLeft from '../../../../icons/ArrowLeft.svg';
import MateSorry from '../../../../icons/mate_sorry.png';
import MateIntro from '../../../../icons/mateIntro.png';
import ArrowUp from '../../../../icons/arrowGrayUp.svg';
import NoReport from '../../../../icons/noReportImage.png';
import { actions as monthlyLookbackUiAction } from '../../../../modules/monthlyLookback/ui';
import {
  NEXT_REPORT_ID,
  SUMMARY_ONE_REPORT_LIMIT,
  COMPARISON_PATTERN,
  LABOR_COST_ANALYSIS_APPEAL_INVISIBLE_DATE,
} from '../monthlyLookbackConstants';
import { genGaLog } from '../../../../gaLogger';
import { Logger } from '../../../../typedef/logger';
import { TrackAction } from '../../../../modules/logging';
import RecordSummary from './RecordSummary';
import SalesAnalysis from './SalesAnalysis';
import LaborCostAnalysisContents from './LaborCostAnalysis';
import MonthlyDetailedSalesAnalysis from './DetailedSalesAnalysis';
import MonthlyMenuAnalysis from './MenuAnalysis';
import PrintFrame from './PrintFrame';
import LaborCostAnalysisAppealContent from './LaborCostAnalysisAppealContent';
//TODO: AIRLAB-12521 ABテストロジック↓
import { isAbTestForGroupIdOddEvenNumber } from '../../../../helpers/abTestHelper';
import { UserData } from '../../../../modules/user';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../../helpers/mclDate';
//TODO: AIRLAB-12521 ABテストロジック↑
type ThroughProps = {
  onLeave: () => void;
  handleHistoryBack?: () => void;
  printMode?: boolean;
  templateLoading?: boolean;
  logging?: (logger: Logger) => TrackAction;
};

type StateProps = {
  summaryData: ApiState<MonthlyLookbackListResponse>;
  lookbacks: ApiState<ReadonlyArray<DetailList>>;
  summaryViewType: SummaryViewType.T;
  stores: ReadonlyArray<StoresData>;
  yearMonth: LocalYearMonthObj;
  loading: boolean;
  shouldShowSummary: boolean;
  shouldShowThreshold: boolean;
  //TODO: AIRLAB-12521 ABテストロジック↓
  userData: UserData | null;
  //TODO: AIRLAB-12521 ABテストロジック↑
};

type State = {
  isOpen: boolean;
};

type DispatchProps = {
  domLoaded: () => void;
  readonly changeDisplayNextReportButton: typeof monthlyLookbackUiAction.changeDisplayNextReportButton;
  readonly changeShowNextReport: typeof monthlyLookbackUiAction.changeShowNextReport;
};
//TODO: AIRLAB-12521 ABテストロジック↓
const Format万 = styled(baseFontSize(16)(postfix(mediumUnit('万'))(FormatTenThousand)))`
  color: ${black};
`;
//TODO: AIRLAB-12521 ABテストロジック↑
type Props = RouteComponentProps<{}> & ThroughProps & StateProps & DispatchProps;

class PageList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidUpdate() {
    this.setState({
      isOpen: true,
    });
  }

  hideButton = () => {
    const { changeDisplayNextReportButton, changeShowNextReport } = this.props;
    changeDisplayNextReportButton(false);
    changeShowNextReport(true);
  };
  //TODO: AIRLAB-12521 ABテストロジック↓
  reportIntroElement = (comparisonType: '1' | '2', goalSalesDiff?: number, previousSalesDiff?: number) => {
    //目標設定あり
    if (goalSalesDiff != null) {
      return this.reportIntroText('goal', goalSalesDiff);
      //前年同月あり
    } else if (COMPARISON_PATTERN.lastYearSameMonth.key === comparisonType && previousSalesDiff != null) {
      return this.reportIntroText('lastYearSameMonth', previousSalesDiff);

      //前月あり
    } else if (COMPARISON_PATTERN.previousMonth.key === comparisonType && previousSalesDiff != null) {
      return this.reportIntroText('previousMonth', previousSalesDiff);
    }
    //比較対象なし
    return (
      <ReportIntroText>
        お疲れ様でした。
        <br />
        振り返りをしてみましょう。
      </ReportIntroText>
    );
  };

  reportIntroText = (comparisonType: keyof typeof COMPARISON_PATTERN, differenceSales: number) => {
    // 例外売上比較用定数
    const thousand = 1000;
    if (differenceSales >= thousand) {
      return (
        <ReportIntroText>
          この月の売上は{COMPARISON_PATTERN[comparisonType].labelText}より
          <Format万 value={differenceSales} />
          円多かったです。{comparisonType === 'goal' && '目標を達成しました！'}
          <br />
          要因を振り返りましょう。
        </ReportIntroText>
      );
    } else if (differenceSales <= -thousand) {
      return (
        <ReportIntroText>
          この月の売上は{COMPARISON_PATTERN[comparisonType].labelText}より
          <Format万 value={differenceSales * -1} />
          円少なかったです。
          <br />
          要因を振り返りましょう。
        </ReportIntroText>
      );
    }
    if (comparisonType === 'goal' && differenceSales != null) {
      if (differenceSales < 0) {
        return (
          <ReportIntroText>
            目標達成まであと一歩でした。
            <br />
            要因を振り返りましょう。
          </ReportIntroText>
        );
      }
      return (
        <ReportIntroText>
          この月は売上目標を達成しました。
          <br />
          要因を振り返りましょう。
        </ReportIntroText>
      );
    }

    return (
      <ReportIntroText>
        この月は{COMPARISON_PATTERN[comparisonType].labelText}と同じくらいの売上でした。
        <br />
        要因を振り返りましょう。
      </ReportIntroText>
    );
  };

  _handleClickDailyReportList = () => {
    const { history, logging } = this.props;
    history.push('/daily_report_list/');
    if (logging != null) {
      logging(genOpenDailyReportLog());
    }
  };

  _handleClickMonthlyCost = () => {
    const { history, logging } = this.props;
    history.push('/cost_prediction_setting/');
    if (logging != null) {
      logging(genOpenMonthlyCostLog());
    }
  };

  _handleClickCloseAppeal = () => {
    const { logging } = this.props;
    localStorage.setItem(
      LABOR_COST_ANALYSIS_APPEAL_CLOSE_INFO_DATE_KEY_NAME,
      mclDayjs().format(formatter.mapiDefaultDate)
    );
    this.setState({
      isOpen: false,
    });
    if (logging != null) {
      logging(genCloseAppealLog());
    }
  };

  //TODO: AIRLAB-12521 ABテストロジック↑
  render() {
    const {
      lookbacks,
      summaryData,
      yearMonth,
      summaryViewType,
      stores,
      loading,
      shouldShowSummary,
      shouldShowThreshold,
      templateLoading,
      domLoaded,
      changeDisplayNextReportButton,
      userData,
    } = this.props;
    const { onLeave, handleHistoryBack, printMode, logging } = this.props;
    const { isOpen } = this.state;
    // TODO: error処理
    if (templateLoading) {
      return (
        <Template.Center>
          <ActivityIndicatorMatekun />
        </Template.Center>
      );
    }
    if (shouldShowSummary) {
      if (
        apiState.isFailed(summaryData) &&
        // @ts-ignore
        summaryData.error.data != null &&
        // @ts-ignore
        summaryData.error.data.returnCode !== returnCodes.replaceGroupId
      ) {
        return (
          <SorryWrapper>
            <SorryImage src={MateSorry} alt="申し訳ございません" />
            <SorryText>データを取得できませんでした。</SorryText>
            <SorryText>お手数ですが、時間をおいて再度お試しください。</SorryText>
          </SorryWrapper>
        );
      }

      if (apiState.isStarted(summaryData)) {
        return (
          <Template.Center>
            <ActivityIndicatorMatekun />
          </Template.Center>
        );
      }
    }

    if (shouldShowThreshold) {
      if (
        apiState.isFailed(lookbacks) &&
        // @ts-ignore
        lookbacks.error.data != null &&
        // @ts-ignore
        lookbacks.error.data.returnCode !== returnCodes.replaceGroupId
      ) {
        return (
          <SorryWrapper>
            <SorryImage src={MateSorry} alt="申し訳ございません" />
            <SorryText>データを取得できませんでした。</SorryText>
            <SorryText>お手数ですが、時間をおいて再度お試しください。</SorryText>
          </SorryWrapper>
        );
      }

      if (!apiState.isCompleted(lookbacks) && !apiState.isFailed(lookbacks)) {
        return (
          <Template.Center>
            <ActivityIndicatorMatekun />
          </Template.Center>
        );
      }
    }

    const noThresholdSales: boolean =
      shouldShowThreshold &&
      lookbacks.type === API_STATE_COMPLETED &&
      lookbacks.payload != null &&
      !lookbacks.payload.find(item => item.salesAnalysis.overallSales.sales > 0);

    // 人件費分析レポート訴求の表示期間をlocalStorageから取得して算出する
    const laborCostAnalysisAppealCloseInfoDate = localStorage.getItem(
      LABOR_COST_ANALYSIS_APPEAL_CLOSE_INFO_DATE_KEY_NAME
    );

    const laborCostAnalysisAppealViewDate =
      laborCostAnalysisAppealCloseInfoDate != null
        ? mclDayjs(laborCostAnalysisAppealCloseInfoDate, formatter.mapiDate)
            .add(LABOR_COST_ANALYSIS_APPEAL_INVISIBLE_DATE, 'day')
            .toLocalDateObj()
        : null;
    /**
     * 表示条件については以下コンフル参照
     * https://one-recruit.atlassian.net/wiki/spaces/ALAB/pages/1023025890
     */
    const hasLaborCostAnalysis: boolean =
      lookbacks.type === API_STATE_COMPLETED &&
      lookbacks.payload != null &&
      lookbacks.payload.find(
        item => item.salesAnalysis.overallSales.sales !== 0 && item.laborCostAnalysis.laborCostRate !== 0
      ) !== undefined;

    const isAppealDisplay: boolean =
      laborCostAnalysisAppealViewDate == null ||
      (laborCostAnalysisAppealViewDate != null &&
        mclDayjs().isAfter(parser.fromDateObject(laborCostAnalysisAppealViewDate)));

    // 検索されたakrCodeを取得する
    const storeSet = new Set();
    lookbacks.type === API_STATE_COMPLETED &&
      lookbacks.payload.forEach(store => {
        storeSet.add(store.akrCode);
      });

    const isShiftUse: boolean = stores
      .filter(store => storeSet.has(store.akrCode))
      .some(store => store.isShiftUse);

    // 全店舗成績表の表示なし＆店舗全てに売上がない場合
    if (!shouldShowSummary && noThresholdSales) {
      return (
        <React.Fragment>
          <NoReportText topMargin={80} bottomMargin={40}>
            選択された月のデータがありません。
          </NoReportText>
          <NoReportImage src={NoReport} alt="noReport" />
          <NoReportText topMargin={32}>
            Airレジで会計を行うと、月ごとの売上や商品分析などのレポートが自動で作成されます。
          </NoReportText>
        </React.Fragment>
      );
    }

    const formattedYearMonth = parser
      .fromYearMonthObject(yearMonth)
      .format(formatter.mapiDefaultYearMonthNotFixed);
    if (loading) {
      setTimeout(domLoaded);
    }

    return !loading ? (
      <PageListWrapper id={printMode ? 'print' : 'preview'} printMode={printMode}>
        {handleHistoryBack && (
          <ArrowArea onClick={handleHistoryBack}>
            <CustomArrowLeft />
            <ArrowText>戻る</ArrowText>
          </ArrowArea>
        )}
        {(summaryData.type === 'API_STATE_FAILED' &&
          // @ts-ignore
          summaryData.error.data != null &&
          // @ts-ignore
          summaryData.error.data.returnCode === returnCodes.replaceGroupId) ||
        (lookbacks.type === 'API_STATE_FAILED' &&
          // @ts-ignore
          lookbacks.error.data != null &&
          // @ts-ignore
          lookbacks.error.data.returnCode === returnCodes.replaceGroupId) ? (
          <Template.Center>
            <Error
              type={ERROR_TYPE_FAILED}
              msg={
                '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
              }
            />
          </Template.Center>
        ) : (
          <React.Fragment>
            {!printMode && (
              <ToTopButton
                onClick={() => {
                  document.getElementById('scrollable')?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <ToTopIcon />
                <ToTopText>
                  ページ
                  <br />
                  トップへ
                </ToTopText>
              </ToTopButton>
            )}

            {/** 最初のページに一度or詳細がない場合 */}
            {shouldShowSummary &&
              summaryData.type === API_STATE_COMPLETED &&
              summaryData.payload != null &&
              (SummaryViewType.isOnceFirstPage(summaryViewType) ||
                !shouldShowThreshold ||
                (lookbacks.type === API_STATE_COMPLETED && lookbacks.payload == null) ||
                noThresholdSales) && (
                <React.Fragment>
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('summary'));
                      //全店舗成績表を表示時、売上レポート（レポート２ページ目）がある場合に次のレポートボタンを表示する
                      lookbacks.type === API_STATE_COMPLETED &&
                        lookbacks.payload != null &&
                        lookbacks.payload.filter(lookback => {
                          return lookback.salesAnalysis.overallSales.sales !== 0;
                        }).length !== 0 &&
                        changeDisplayNextReportButton(true);
                    }}
                  ></Waypoint>
                  <RecordSummary
                    summary={summaryData.payload.summary}
                    storeList={summaryData.payload.storeList}
                    yearMonth={formattedYearMonth}
                    getStoreName={code => getStoreName(stores, code)}
                    printMode={printMode}
                    hideButton={this.hideButton}
                  />
                </React.Fragment>
              )}
            {lookbacks.type === API_STATE_COMPLETED && lookbacks.payload != null
              ? lookbacks.payload
                  .filter(lookback => {
                    return lookback.salesAnalysis.overallSales.sales !== 0;
                  })
                  .map((lookback, index) => {
                    const {
                      akrCode,
                      salesAnalysis,
                      laborCostAnalysis,
                      menuAnalysis,
                      detailedSalesAnalysis,
                      productAnalysis,
                    } = lookback;
                    const { overallSales } = salesAnalysis;
                    const { sales } = overallSales;
                    const shopName = getStoreName(stores, akrCode);
                    const isfirstStoreReport: boolean = index === 0;
                    const store = stores.find(store => {
                      return store.akrCode === akrCode;
                    });

                    return (
                      <Waypoint
                        onLeave={() => {
                          onLeave();
                        }}
                        topOffset={'150%'}
                        fireOnRapidScroll={false}
                        key={index}
                      >
                        {sales !== 0 && (
                          <div>
                            {/* TODO: AIRLAB-12521 ABテストロジック↓ */}
                            {!isAbTestForGroupIdOddEvenNumber(userData != null ? userData.groupId : '', 2) &&
                              printMode == null && (
                                <ReportIntroArea isMargin={!isfirstStoreReport || shouldShowSummary}>
                                  <ShopName>{shopName}</ShopName>
                                  <ReportIntroWrapper>
                                    <StyledImg src={MateIntro} alt="レポートイントロメイトくん" />
                                    {this.reportIntroElement(
                                      salesAnalysis.comparisonType,
                                      salesAnalysis.overallSales.goalSalesDiff,
                                      salesAnalysis.overallSales.previousSalesDiff
                                    )}
                                  </ReportIntroWrapper>
                                </ReportIntroArea>
                              )}
                            {/* TODO: AIRLAB-12521 ABテストロジック↑ */}
                            <div>
                              <PrintFrame
                                title="売上分析"
                                shopName={shopName}
                                yearMonth={formattedYearMonth}
                                isPrintMode={printMode}
                                id="lookback_report_area"
                                hideButton={
                                  shouldShowSummary &&
                                  summaryData.type === API_STATE_COMPLETED &&
                                  summaryData.payload != null &&
                                  summaryData.payload.storeList.length < SUMMARY_ONE_REPORT_LIMIT
                                    ? this.hideButton
                                    : undefined
                                }
                              >
                                {isfirstStoreReport && (
                                  <Waypoint
                                    onEnter={() => {
                                      logging && logging(genReportLog('sales_analysis'));
                                      //全店舗成績表がない場合、売上分析が先頭にくる為、次のレポートボタンを表示
                                      !shouldShowSummary && changeDisplayNextReportButton(true);
                                    }}
                                  ></Waypoint>
                                )}
                                <SalesAnalysis data={salesAnalysis} month={yearMonth.month} />
                                {/* 全店舗成績表の表示なし＆次のレポートボタンのスクロール位置 */}
                                {sales !== 0 && index === 0 && !shouldShowSummary && (
                                  // waypointの判定を部分をbottomOffsetで調整（次のレポートのタイトル部分が見えるくらい）
                                  <Waypoint bottomOffset={'10%'} onEnter={this.hideButton}>
                                    <div id={NEXT_REPORT_ID}></div>
                                  </Waypoint>
                                )}
                              </PrintFrame>
                              {sales !== 0 && (
                                <SwitchDisplayedDetailedSalesAnalysisAndMenuAnalysis
                                  productAnalysis={productAnalysis}
                                  detailedSalesAnalysis={detailedSalesAnalysis}
                                  menuAnalysis={menuAnalysis}
                                  shopName={shopName}
                                  formattedYearMonth={formattedYearMonth}
                                  yearMonth={yearMonth}
                                  printMode={printMode}
                                  isFirstStoreReport={isfirstStoreReport}
                                  genReportLog={genReportLog}
                                  logging={logging != null ? logging : undefined}
                                />
                              )}
                              {sales !== 0 && laborCostAnalysis.laborCostRate !== 0 && (
                                <PrintFrame
                                  title="人件費分析"
                                  shopName={shopName}
                                  yearMonth={formattedYearMonth}
                                  isPrintMode={printMode}
                                >
                                  {isfirstStoreReport && (
                                    <Waypoint
                                      onEnter={() => {
                                        logging != null && logging(genReportLog('labor_cost_analysis'));
                                      }}
                                    ></Waypoint>
                                  )}
                                  <LaborCostAnalysisContents
                                    data={laborCostAnalysis}
                                    yearMonth={yearMonth}
                                    isShiftAppeal={!store?.isShiftUse && !printMode}
                                    logging={logging}
                                  />
                                </PrintFrame>
                              )}
                              {/* シフト未連携で人件費レポートが表示されない場合は人件費レポート利用の訴求を表示 */}
                              {!printMode &&
                                index === 0 &&
                                !hasLaborCostAnalysis &&
                                !isShiftUse &&
                                isAppealDisplay &&
                                isOpen && (
                                  <LaborCostAnalysisAppealContent
                                    clickDailyReportList={this._handleClickDailyReportList}
                                    clickMonthlyCost={this._handleClickMonthlyCost}
                                    clickCloseAppeal={this._handleClickCloseAppeal}
                                    logging={logging}
                                  />
                                )}
                            </div>
                          </div>
                        )}
                      </Waypoint>
                    );
                  })
              : null}
          </React.Fragment>
        )}
      </PageListWrapper>
    ) : (
      <Template.Center>
        <ActivityIndicatorMatekun />
      </Template.Center>
    );
  }
}

// 売上詳細分析のみを表示するSFC
const DetailedSalesAnalysisPages = ({
  isFirstStoreReport,
  productAnalysis,
  detailedSalesAnalysis,
  shopName,
  formattedYearMonth,
  yearMonth,
  printMode,
  genReportLog,
  logging,
}: {
  isFirstStoreReport: boolean;
  productAnalysis?: ProductAnalysis;
  detailedSalesAnalysis: DetailedSalesAnalysis;
  shopName: string;
  formattedYearMonth: string;
  yearMonth: LocalYearMonthObj;
  printMode?: boolean;
  genReportLog: (report: string) => Logger;
  logging?: (logger: Logger) => TrackAction;
}): React.ReactElement => {
  const { isInputLunchSales, isInputOutsideSales, lunchPage, dinnerPage } = detailedSalesAnalysis;
  if (isInputLunchSales && lunchPage != null) {
    // パターン①③
    return (
      <React.Fragment>
        {shouldShowDetailedSalesPage(lunchPage) && (
          <PrintFrame
            title="ランチ売上詳細分析"
            shopName={shopName}
            yearMonth={formattedYearMonth}
            isPrintMode={printMode}
          >
            {isFirstStoreReport && (
              <Waypoint
                onEnter={() => {
                  logging != null && logging(genReportLog('detailed_sales_analysis'));
                }}
              ></Waypoint>
            )}
            <MonthlyDetailedSalesAnalysis
              page={lunchPage}
              analysisType={'ランチ'}
              yearMonth={yearMonth}
              productAnalysis={productAnalysis}
            />
          </PrintFrame>
        )}
        {shouldShowDetailedSalesPage(dinnerPage) && (
          <PrintFrame
            title="ディナー売上詳細分析"
            shopName={shopName}
            yearMonth={formattedYearMonth}
            isPrintMode={printMode}
          >
            {isFirstStoreReport && (
              <Waypoint
                onEnter={() => {
                  logging != null && logging(genReportLog('detailed_sales_analysis'));
                }}
              ></Waypoint>
            )}
            <MonthlyDetailedSalesAnalysis
              page={dinnerPage}
              analysisType={'ディナー'}
              yearMonth={yearMonth}
              productAnalysis={productAnalysis}
            />
          </PrintFrame>
        )}
      </React.Fragment>
    );
  } else {
    if (!shouldShowDetailedSalesPage(dinnerPage)) {
      return <React.Fragment></React.Fragment>;
    }

    // パターン②
    if (isInputOutsideSales) {
      return (
        <PrintFrame
          title="店内売上詳細分析"
          shopName={shopName}
          yearMonth={formattedYearMonth}
          isPrintMode={printMode}
        >
          {isFirstStoreReport && (
            <Waypoint
              onEnter={() => {
                logging != null && logging(genReportLog('detailed_sales_analysis'));
              }}
            ></Waypoint>
          )}
          {/* Note: パターン②：dinnerPageは店内として扱います */}
          <MonthlyDetailedSalesAnalysis
            page={dinnerPage}
            analysisType={'店内'}
            yearMonth={yearMonth}
            productAnalysis={productAnalysis}
          />
        </PrintFrame>
      );
    } else {
      return (
        <PrintFrame
          title="売上詳細分析"
          shopName={shopName}
          yearMonth={formattedYearMonth}
          isPrintMode={printMode}
        >
          {isFirstStoreReport && (
            <Waypoint
              onEnter={() => {
                logging != null && logging(genReportLog('detailed_sales_analysis'));
              }}
            ></Waypoint>
          )}
          {/* Note: パターン④：dinnerPageは売上全体として扱います */}
          <MonthlyDetailedSalesAnalysis
            page={dinnerPage}
            analysisType={''}
            yearMonth={yearMonth}
            productAnalysis={productAnalysis}
          />
        </PrintFrame>
      );
    }
  }
};

// 商品分析のみを表示するSFC
const MenuAnalysisPages = ({
  isFirstStoreReport,
  productAnalysis,
  menuAnalysis,
  shopName,
  formattedYearMonth,
  yearMonth,
  printMode,
  genReportLog,
  logging,
}: {
  isFirstStoreReport: boolean;
  productAnalysis?: ProductAnalysis;
  menuAnalysis: MenuAnalysis;
  shopName: string;
  formattedYearMonth: string;
  yearMonth: LocalYearMonthObj;
  printMode?: boolean;
  genReportLog: (report: string) => Logger;
  logging?: (logger: Logger) => TrackAction;
}): React.ReactElement => {
  const {
    lunchMenuReport,
    dinnerMenuReport,
    noDisassemblyMenuReport,
    isInputLunchSales,
    isInputOutsideSales,
  } = menuAnalysis;

  if (isInputOutsideSales) {
    if (isInputLunchSales) {
      // パターン① ランチ+ディナー ※店外はロジック上の都合で一時削除
      return (
        <React.Fragment>
          {lunchMenuReport != null &&
            (lunchMenuReport.drinkAnalysis != null || lunchMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title="ランチ商品分析"
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('detailed_sales_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={lunchMenuReport}
                  yearMonth={yearMonth}
                  analysisType={'ランチ'}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                />
              </PrintFrame>
            )}
          {dinnerMenuReport != null &&
            (dinnerMenuReport.drinkAnalysis != null || dinnerMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title={'ディナー商品分析'}
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('menu_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={dinnerMenuReport}
                  yearMonth={yearMonth}
                  analysisType={'ディナー'}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                />
              </PrintFrame>
            )}
        </React.Fragment>
      );
    } else {
      // パターン② 店内 ※店外はロジック上の都合で一時削除
      return (
        <React.Fragment>
          {dinnerMenuReport != null &&
            (dinnerMenuReport.drinkAnalysis != null || dinnerMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title={'店内商品分析'}
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('menu_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={dinnerMenuReport}
                  yearMonth={yearMonth}
                  analysisType={'店内'}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                />
              </PrintFrame>
            )}
        </React.Fragment>
      );
    }
  } else {
    if (isInputLunchSales) {
      // パターン③ ランチ+ディナー
      return (
        <React.Fragment>
          {lunchMenuReport != null &&
            (lunchMenuReport.drinkAnalysis != null || lunchMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title="ランチ商品分析"
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('menu_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={lunchMenuReport}
                  yearMonth={yearMonth}
                  analysisType={'ランチ'}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                />
              </PrintFrame>
            )}
          {dinnerMenuReport != null &&
            (dinnerMenuReport.drinkAnalysis != null || dinnerMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title={'ディナー商品分析'}
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('menu_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={dinnerMenuReport}
                  yearMonth={yearMonth}
                  analysisType={'ディナー'}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                />
              </PrintFrame>
            )}
        </React.Fragment>
      );
    } else {
      // パターン④ 分解なし
      return (
        <React.Fragment>
          {noDisassemblyMenuReport != null &&
            (noDisassemblyMenuReport.drinkAnalysis != null ||
              noDisassemblyMenuReport.foodAnalysis != null) && (
              <PrintFrame
                title={'商品分析'}
                shopName={shopName}
                yearMonth={formattedYearMonth}
                isPrintMode={printMode}
              >
                {isFirstStoreReport && (
                  <Waypoint
                    onEnter={() => {
                      logging != null && logging(genReportLog('menu_analysis'));
                    }}
                  ></Waypoint>
                )}
                <MonthlyMenuAnalysis
                  analysis={noDisassemblyMenuReport}
                  productAnalysis={
                    !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
                      ? productAnalysis?.productReport
                      : undefined
                  }
                  yearMonth={yearMonth}
                  analysisType=""
                />
              </PrintFrame>
            )}
        </React.Fragment>
      );
    }
  }
};

// 売上詳細分析と商品分析ページが交互に表示される
const DetailedSalesAnalysisAndMenuAnalysisPages = ({
  isFirstStoreReport,
  productAnalysis,
  detailedSalesAnalysis,
  menuAnalysis,
  shopName,
  formattedYearMonth,
  yearMonth,
  printMode,
  genReportLog,
  logging,
}: {
  isFirstStoreReport: boolean;
  productAnalysis?: ProductAnalysis;
  detailedSalesAnalysis: DetailedSalesAnalysis;
  menuAnalysis: MenuAnalysis;
  shopName: string;
  formattedYearMonth: string;
  yearMonth: LocalYearMonthObj;
  printMode?: boolean;
  genReportLog: (report: string) => Logger;
  logging?: (logger: Logger) => TrackAction;
}): React.ReactElement => {
  const { lunchPage, dinnerPage } = detailedSalesAnalysis;
  const { lunchMenuReport, dinnerMenuReport } = menuAnalysis;

  return (
    <React.Fragment>
      {lunchPage != null && shouldShowDetailedSalesPage(lunchPage) && (
        <PrintFrame
          title="ランチ売上詳細分析"
          shopName={shopName}
          yearMonth={formattedYearMonth}
          isPrintMode={printMode}
        >
          {isFirstStoreReport && (
            <Waypoint
              onEnter={() => {
                logging != null && logging(genReportLog('detailed_sales_analysis'));
              }}
            ></Waypoint>
          )}
          <MonthlyDetailedSalesAnalysis
            page={lunchPage}
            analysisType={'ランチ'}
            yearMonth={yearMonth}
            productAnalysis={productAnalysis}
          />
        </PrintFrame>
      )}
      {lunchMenuReport != null &&
        (lunchMenuReport.drinkAnalysis != null || lunchMenuReport.foodAnalysis != null) && (
          <PrintFrame
            title="ランチ商品分析"
            shopName={shopName}
            yearMonth={formattedYearMonth}
            isPrintMode={printMode}
          >
            {isFirstStoreReport && (
              <Waypoint
                onEnter={() => {
                  logging != null && logging(genReportLog('menu_analysis'));
                }}
              ></Waypoint>
            )}
            <MonthlyMenuAnalysis analysis={lunchMenuReport} yearMonth={yearMonth} analysisType={'ランチ'} />
          </PrintFrame>
        )}
      {shouldShowDetailedSalesPage(dinnerPage) && (
        <PrintFrame
          title="ディナー売上詳細分析"
          shopName={shopName}
          yearMonth={formattedYearMonth}
          isPrintMode={printMode}
        >
          {isFirstStoreReport && (
            <Waypoint
              onEnter={() => {
                logging != null && logging(genReportLog('detailed_sales_analysis'));
              }}
            ></Waypoint>
          )}
          <MonthlyDetailedSalesAnalysis
            page={dinnerPage}
            analysisType={'ディナー'}
            yearMonth={yearMonth}
            productAnalysis={productAnalysis}
          />
        </PrintFrame>
      )}
      {dinnerMenuReport != null &&
        (dinnerMenuReport.drinkAnalysis != null || dinnerMenuReport.foodAnalysis != null) && (
          <PrintFrame
            title={'ディナー商品分析'}
            shopName={shopName}
            yearMonth={formattedYearMonth}
            isPrintMode={printMode}
          >
            {isFirstStoreReport && (
              <Waypoint
                onEnter={() => {
                  logging != null && logging(genReportLog('menu_analysis'));
                }}
              ></Waypoint>
            )}
            <MonthlyMenuAnalysis
              analysis={dinnerMenuReport}
              yearMonth={yearMonth}
              analysisType={'ディナー'}
            />
          </PrintFrame>
        )}
    </React.Fragment>
  );
};

// 条件によって売上詳細分析ページと商品分析ページの出しわけをする
const SwitchDisplayedDetailedSalesAnalysisAndMenuAnalysis = ({
  isFirstStoreReport,
  productAnalysis,
  detailedSalesAnalysis,
  menuAnalysis,
  shopName,
  formattedYearMonth,
  yearMonth,
  printMode,
  genReportLog,
  logging,
}: {
  isFirstStoreReport: boolean;
  productAnalysis?: ProductAnalysis;
  detailedSalesAnalysis: DetailedSalesAnalysis;
  menuAnalysis?: MenuAnalysis;
  shopName: string;
  formattedYearMonth: string;
  yearMonth: LocalYearMonthObj;
  printMode?: boolean;
  genReportLog: (report: string) => Logger;
  logging?: (logger: Logger) => TrackAction;
}): React.ReactElement => {
  // メイト・レジが同時にアクティベートされ、にレジにメニューを登録して使い始める前に月次レポートを開いた場合
  // 対象の店舗はEntityが1件も存在しないのでこのObjectはnullとなります
  if (menuAnalysis == null) {
    return (
      <DetailedSalesAnalysisPages
        detailedSalesAnalysis={detailedSalesAnalysis}
        shopName={shopName}
        formattedYearMonth={formattedYearMonth}
        yearMonth={yearMonth}
        printMode={printMode}
        isFirstStoreReport={isFirstStoreReport}
        genReportLog={genReportLog}
        logging={logging}
      />
    );
  } else {
    // 売上詳細ページと商品分析ページがどちらもランチ・ディナーを表示する場合、並び順が交互になる
    // どちらかのisInputLunchSalesがfalseなら、売上詳細ページをまとめて表示後、商品分析ページを表示する
    if (detailedSalesAnalysis.isInputLunchSales && menuAnalysis.isInputLunchSales) {
      return (
        <DetailedSalesAnalysisAndMenuAnalysisPages
          detailedSalesAnalysis={detailedSalesAnalysis}
          menuAnalysis={menuAnalysis}
          shopName={shopName}
          formattedYearMonth={formattedYearMonth}
          yearMonth={yearMonth}
          printMode={printMode}
          productAnalysis={productAnalysis}
          isFirstStoreReport={isFirstStoreReport}
          genReportLog={genReportLog}
          logging={logging}
        />
      );
    } else {
      return (
        <React.Fragment>
          <DetailedSalesAnalysisPages
            detailedSalesAnalysis={detailedSalesAnalysis}
            shopName={shopName}
            formattedYearMonth={formattedYearMonth}
            yearMonth={yearMonth}
            printMode={printMode}
            productAnalysis={productAnalysis}
            isFirstStoreReport={isFirstStoreReport}
            genReportLog={genReportLog}
            logging={logging}
          />
          <MenuAnalysisPages
            productAnalysis={productAnalysis}
            menuAnalysis={menuAnalysis}
            shopName={shopName}
            formattedYearMonth={formattedYearMonth}
            yearMonth={yearMonth}
            printMode={printMode}
            isFirstStoreReport={isFirstStoreReport}
            genReportLog={genReportLog}
            logging={logging}
          />
        </React.Fragment>
      );
    }
  }
};

const shouldShowDetailedSalesPage = (page: DetailedSalesAnalysisPage) => {
  return (
    (page.course != null && page.course.sales !== 0) || (page.noCourse != null && page.noCourse.sales !== 0)
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: state.user.data != null ? state.user.data.stores : [],
  lookbacks: state.monthlyLookbackApi.details,
  summaryData: state.monthlyLookbackApi.summaryData,
  yearMonth: state.monthlyLookbackUi.viewYearMonth,
  summaryViewType: state.monthlyLookbackUi.viewSummaryViewType,
  loading: state.monthlyLookbackUi.loading,
  shouldShowSummary: state.monthlyLookbackUi.shouldShowSummary,
  shouldShowThreshold: state.monthlyLookbackUi.shouldShowThreshold,
  userData: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  domLoaded: () => dispatch(UiActions.domLoaded()),
  ...bindActionCreators(
    {
      changeDisplayNextReportButton: monthlyLookbackUiAction.changeDisplayNextReportButton,
      changeShowNextReport: monthlyLookbackUiAction.changeShowNextReport,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageList));

const PageListWrapper = styled.div<{ printMode: boolean | undefined }>`
  width: ${props => (props.printMode ? '29.7cm' : '930px')};
  ${props =>
    !props.printMode &&
    `
  height: 100%;
  margin: 0 auto;
  `}
  margin-bottom: 100px;
`;

const ArrowArea = styled.div`
  position: fixed;
  bottom: 30px;
  left: -28px;
  width: 100px;
  height: 44px;
  padding-right: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0 28px 28px 0;
  text-align: right;
  background-color: #fff;
  cursor: pointer;
  z-index: 1;
  @media print {
    display: none;
  }
`;
const CustomArrowLeft = styled(ArrowLeft)`
  vertical-align: middle;
`;
const ArrowText = styled.span`
  font-size: 14px;
  line-height: 44px;
  vertical-align: middle;
`;

const ToTopButton = styled.div`
  position: fixed;
  bottom: 30px;
  right: 16px;
  width: 56px;
  height: 56px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 28px;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  z-index: 1;
  @media print {
    display: none;
  }
`;

const ToTopText = styled.p`
  margin: 5px auto 0;
  text-align: center;
  width: 40px;
  height: 20px;
  font-size: 10px;
  line-height: 1;
`;

const ToTopIcon = styled(ArrowUp)`
  display: block;
  margin: 6px auto;
`;

const SorryWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`;

const SorryImage = styled.img`
  display: block;
  width: 82px;
  margin: 0 auto 30px;
`;

const SorryText = styled.p`
  text-align: center;
`;

const NoReportImage = styled.img`
  display: block;
  margin: 0 auto 30px;
  width: 642px;
  height: 280px;
`;

const NoReportText = styled.div<{ topMargin?: number; bottomMargin?: number }>`
  font-size: 16px;
  text-align: center;
  margin: 80px 0 40px;
  ${props => props.topMargin != null && `margin-top: ${props.topMargin}px;`}
  ${props => props.bottomMargin != null && `margin-bottom: ${props.bottomMargin}px;`}
`;

//TODO: AIRLAB-12521 ABテストロジック↓
const ReportIntroArea = styled.div<{ isMargin: boolean }>`
  width: 100%;
  height: 120px;
  color: ${black};
  ${props => props.isMargin && ' margin-top: 40px;'};
`;

const ReportIntroWrapper = styled.div`
  width: 100%;
  height: 82px;
  background: ${hoverAndSelectedColor};
  border-radius: 8px;
  margin-top: 16px;
  overflow: hidden;
  display: flex;
`;

const ShopName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StyledImg = styled.img`
  width: 68px;
  height: 76px;
  margin-top: 15px;
  margin-left: 23px;
`;

const ReportIntroText = styled.div`
  font-size: 16px;
  line-height: 26px;
  margin-top: 18px;
  margin-left: 23px;
`;
//TODO: AIRLAB-12521 ABテストロジック↑

const genReportLog = (reportName: string): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'monthly_lookback_preview',
    `impression_${reportName}_report`,
    {},
    {},
    'impression'
  );
};

const genOpenDailyReportLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_panel',
    'dairy_report_button_click',
    {},
    {},
    'click'
  );
};

const genOpenMonthlyCostLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_panel',
    'monthly_input_button_click',
    {},
    {},
    'click'
  );
};

const genCloseAppealLog = (): Logger => {
  return genGaLog(
    'monthly_lookback_preview',
    'labor_cost_analysis_crossuse_panel',
    'panel_close',
    {},
    {},
    'click'
  );
};
