import { INDICES_TYPE, BANNER_TYPE, BANNER_TYPE_NONE } from '../ui/pages/Store/storesConstants';
import { ErrorType, ApiState, apiState } from '../typedef/api/Utility';
import { Logger, Tracker } from '../typedef/logger';
import * as AkrCode from '../typedef/AkrCode';
import { RealtimeSummariesResponse } from '../typedef/api/Realtime';
import { SalesCustomizeItemList, SalesCustomizeItem, TaxSettingStatus } from '../typedef/api/StoreIndices';
import { GroupFiscalYearResponse } from '../typedef/api/BudgetSetting';
import { PresenceOrAbsenceOfSalesListResponse } from '../typedef/api/PresenceOrAbsenceOfSalesList';

export type SalesCustomizeConfig = ReadonlyArray<SalesCustomizeItem>;

export type State = {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly realTimeLoading: boolean;
  readonly realTimeLoaded: boolean;
  readonly isDisplayedStoreList: boolean;
  readonly realTimeData: RealtimeSummariesResponse;
  readonly selectedStores: Set<string>;
  readonly isChangeSelectedStores: boolean;
  readonly selectedAkrCode?: string | null;
  readonly error?: ErrorType;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly selectedBannerType: BANNER_TYPE;
  readonly storeSalesCustomizeItem: ApiState<SalesCustomizeItemList>;
  readonly salesCustomizeItem: ApiState<SalesCustomizeItemList>;
  readonly isOpenSalesCustomizeConfigModal: boolean;
  readonly isEditingConfig: boolean;
  readonly isOpenConfigDialog: boolean;
  readonly postSalesCustomizeConfig: ApiState<{}>;
  readonly isOpenFiscalYearSettingsModal: boolean;
  readonly fetchGroupFiscalYearInfoState: ApiState<GroupFiscalYearResponse>;
  readonly presenceOrAbsenceOfSalesListState: ApiState<PresenceOrAbsenceOfSalesListResponse>;
  readonly isChangeAssignedStoreFlag: boolean;
  readonly taxSettingStatus: ApiState<TaxSettingStatus>;
}; // Action Types

export const USER_FETCH_STORES_START = 'user/FETCH_STORES_START';
export const FETCH_REALTIME_STORES_START = 'sys/FETCH_REALTIME_STORES_START';
export const FETCH_REALTIME_STORES_SUCCESS = 'sys/FETCH_REALTIME_STORES_SUCCESS';
export const FETCH_REALTIME_STORES_FAIL = 'sys/FETCH_REALTIME_STORES_FAIL';
export const CLEAR_STATE_STORES = 'sys/CLEAR_STATE_STORES';
export const SELECT_STORE = 'sys/SELECT_STORE';
export const SET_SELECTED_STORE = 'sys/SET_SELECTED_STORE';
export const CLEAR_STATE_STORE = 'store/CLEAR_STATE_STORE';
export const RESET_STATE_STORES = 'store/RESET_STATE_STORES';
export const CHANGE_SELECT_STORE = 'store/CHANGE_SELECT_STORE';
export const RESET_SELECTED_STORE_FLAG = 'store/RESET_SELECTED_STORE_FLAG';
export const CHANGE_DISPLAYED_STORE_LIST = 'store/CHANGE_DISPLAYED_STORE_LIST';
export const CHANGE_SELECTE_INDICES_TYPE = 'store/CHANGE_SELECTE_INDICES_TYPE';
export const START_FETCH_STORE_SALES_CUSTOMIZE_ITEM = 'store/START_FETCH_STORE_SALES_CUSTOMIZE_ITEM';
export const SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM = 'store/SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM';
export const FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM = 'store/FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM';
const START_FETCH_SALES_CUSTOMIZE_ITEM = 'store/START_FETCH_SALES_CUSTOMIZE_ITEM';
export const SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM = 'store/SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM';
export const FAIL_FETCH_SALES_CUSTOMIZE_ITEM = 'store/FAIL_FETCH_SALES_CUSTOMIZE_ITEM';
export const CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE = 'store/CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE';
export const RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE = 'store/RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE';
export const START_POST_SALES_CUSTOMIZE_CONFIG = 'store/START_POST_SALES_CUSTOMIZE_CONFIG';
export const SUCCESS_POST_SALES_CUSTOMIZE_CONFIG = 'store/SUCCESS_POST_SALES_CUSTOMIZE_CONFIG';
export const FAILURE_POST_SALES_CUSTOMIZE_CONFIG = 'store/FAILURE_POST_SALES_CUSTOMIZE_CONFIG';
export const EDIT_CONFIG = 'store/EDIT_CONFIG';
export const CHANGE_CONFIG_DIALOG_STATE = 'store/CHANGE_CONFIG_DIALOG_STATE';
export const START_POST_FISCAL_YEAR_SETTINGS = 'store/START_POST_FISCAL_YEAR_SETTINGS';
export const CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE = 'store/CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE';
export const CLOSE_FISCAL_YEAR_SETTINGS_MODAL = 'store/CLOSE_FISCAL_YEAR_SETTINGS_MODAL';
export const START_FETCH_GROUP_FISCAL_YEAR = 'store/START_FETCH_GROUP_FISCAL_YEAR';
export const SUCCESS_FETCH_GROUP_FISCAL_YEAR = 'store/SUCCESS_FETCH_GROUP_FISCAL_YEAR';
export const FAIL_FETCH_GROUP_FISCAL_YEAR = 'store/FAIL_FETCH_GROUP_FISCAL_YEAR';
export const FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START = 'store/FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START';
export const FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS =
  'store/FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS';
export const FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL = 'store/FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL';
export const FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END = 'store/FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END';
export const CLEAR_REALTIME_STORES = 'store/CLEAR_REALTIME_STORES';
export const CHANGE_SELECT_BANNER_TYPE = 'store/CHANGE_BANNER_TYPE';
export const CLEAR_BANNER_TYPE = 'store/CLEAR_BANNER_TYPE';
export const CHANGE_ASSIGNED_STORE: 'store/CHANGE_ASSIGNED_STORE' = 'store/CHANGE_ASSIGNED_STORE';
export const FETCH_TAX_SETTING_STATUS_START = 'store/FETCH_TAX_SETTING_STATUS_START';
export const FETCH_TAX_SETTING_STATUS_SUCCESS = 'store/FETCH_TAX_SETTING_STATUS_SUCCESS';
export const FETCH_TAX_SETTING_STATUS_FAIL = 'store/FETCH_TAX_SETTING_STATUS_FAIL';
export const CLEAR_TAX_SETTING_STATUS = 'store/CLEAR_TAX_SETTING_STATUS';

export const types = {
  USER_FETCH_STORES_START,
  FETCH_REALTIME_STORES_START,
  FETCH_REALTIME_STORES_SUCCESS,
  FETCH_REALTIME_STORES_FAIL,
  CLEAR_STATE_STORES,
  SELECT_STORE,
  SET_SELECTED_STORE,
  CLEAR_STATE_STORE,
  RESET_STATE_STORES,
  CHANGE_SELECT_STORE,
  RESET_SELECTED_STORE_FLAG,
  CHANGE_DISPLAYED_STORE_LIST,
  CHANGE_SELECTE_INDICES_TYPE,
  START_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
  SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
  FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
  START_FETCH_SALES_CUSTOMIZE_ITEM,
  SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM,
  FAIL_FETCH_SALES_CUSTOMIZE_ITEM,
  CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE,
  RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE,
  START_POST_SALES_CUSTOMIZE_CONFIG,
  SUCCESS_POST_SALES_CUSTOMIZE_CONFIG,
  FAILURE_POST_SALES_CUSTOMIZE_CONFIG,
  EDIT_CONFIG,
  CHANGE_CONFIG_DIALOG_STATE,
  START_POST_FISCAL_YEAR_SETTINGS,
  CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE,
  CLOSE_FISCAL_YEAR_SETTINGS_MODAL,
  START_FETCH_GROUP_FISCAL_YEAR,
  SUCCESS_FETCH_GROUP_FISCAL_YEAR,
  FAIL_FETCH_GROUP_FISCAL_YEAR,
  FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START,
  FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS,
  FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL,
  FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END,
  CLEAR_REALTIME_STORES,
  CHANGE_SELECT_BANNER_TYPE,
  CLEAR_BANNER_TYPE,
  CHANGE_ASSIGNED_STORE,
  FETCH_TAX_SETTING_STATUS_START,
  FETCH_TAX_SETTING_STATUS_SUCCESS,
  FETCH_TAX_SETTING_STATUS_FAIL,
  CLEAR_TAX_SETTING_STATUS,
};
export type UserFetchStoresStartAction = {
  readonly type: 'user/FETCH_STORES_START';
};
export type FetchRealtimeStoresStartAction = {
  readonly type: 'sys/FETCH_REALTIME_STORES_START';
};
export type FetchRealtimeStoresSuccessAction = {
  readonly type: 'sys/FETCH_REALTIME_STORES_SUCCESS';
  readonly payload: RealtimeSummariesResponse;
};
export type FetchRealtimeStoresFailAction = {
  readonly type: 'sys/FETCH_REALTIME_STORES_FAIL';
  readonly payload: ErrorType;
};
export type ClearStateStoresAction = {
  readonly type: 'sys/CLEAR_STATE_STORES';
};
export type SelectStoreAction = {
  readonly type: 'sys/SELECT_STORE';
  readonly payload: AkrCode.T;
  readonly meta?: Tracker;
};
export type SetSelectedStoreAction = {
  readonly type: 'sys/SET_SELECTED_STORE';
  readonly payload?: string | null;
};
export type ClickShowMoreAction = {
  readonly type: 'sys/CLICK_SHOW_MORE';
  readonly meta?: Tracker;
};
export type ClearStateStoreAction = {
  readonly type: 'store/CLEAR_STATE_STORE';
};
export type ResetStateStoresAction = {
  readonly type: 'store/RESET_STATE_STORES';
  readonly payload: string;
};
export type ChangeSelectStoreAction = {
  readonly type: typeof CHANGE_SELECT_STORE;
  readonly payload: Set<string>;
};
export type ResetSelectedStoreFlagAction = {
  readonly type: typeof RESET_SELECTED_STORE_FLAG;
};
export type ChangeDisplayedStoreListAction = {
  readonly type: typeof CHANGE_DISPLAYED_STORE_LIST;
  readonly payload: boolean;
};
export type ChangeSelecteIndicesTypeAction = {
  readonly type: 'store/CHANGE_SELECTE_INDICES_TYPE';
  readonly payload: keyof typeof INDICES_TYPE;
  readonly meta?: Tracker;
};

export type StartFetchStoreSalesCustomizeItemAction = {
  readonly type: typeof START_FETCH_STORE_SALES_CUSTOMIZE_ITEM;
  readonly payload: { akrCode: AkrCode.T };
};

export type SuccessStoreSalesCustomizeItemAction = {
  readonly type: typeof SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM;
  readonly payload: SalesCustomizeItemList;
};

export type FailStoreSalesCustomizeItemAction = {
  readonly type: typeof FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM;
  readonly payload: ErrorType;
};

export type StartFetchSalesCustomizeItemAction = {
  readonly type: typeof START_FETCH_SALES_CUSTOMIZE_ITEM;
};

export type SuccessSalesCustomizeItemAction = {
  readonly type: 'store/SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM';
  readonly payload: SalesCustomizeItemList;
};

export type FailSalesCustomizeItemAction = {
  readonly type: 'store/FAIL_FETCH_SALES_CUSTOMIZE_ITEM';
  readonly payload: ErrorType;
};

export type ChangeSalesCustomizeConfigModalStateAction = {
  readonly type: typeof CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE;
};

export type ResetSalesCustomizeConfigModalState = {
  readonly type: typeof RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE;
};

export type StartPostSalesCustomizeConfigAction = {
  readonly type: typeof START_POST_SALES_CUSTOMIZE_CONFIG;
  readonly payload: SalesCustomizeConfig;
};

export type SuccessPostSalesCustomizeConfigAction = {
  readonly type: typeof SUCCESS_POST_SALES_CUSTOMIZE_CONFIG;
};

export type FailurePostSalesCustomizeConfigAction = {
  readonly type: typeof FAILURE_POST_SALES_CUSTOMIZE_CONFIG;
  readonly payload: ErrorType;
};

export type EditConfigAction = {
  readonly type: typeof EDIT_CONFIG;
};

export type ChangeConfigDialogStateAction = {
  readonly type: typeof CHANGE_CONFIG_DIALOG_STATE;
};

export type StartPostFiscalYearSettingsAction = {
  readonly type: typeof START_POST_FISCAL_YEAR_SETTINGS;
  readonly payload: number;
};

export type ChangeFiscalYearSettingsModalStateAction = {
  readonly type: typeof CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE;
};

export type CloseFiscalYearSettingsModalAction = {
  readonly type: typeof CLOSE_FISCAL_YEAR_SETTINGS_MODAL;
};

export type StartFetchGroupFiscalYearAction = {
  readonly type: typeof START_FETCH_GROUP_FISCAL_YEAR;
};

export type SuccessFetchGroupFiscalYearAction = {
  readonly type: typeof SUCCESS_FETCH_GROUP_FISCAL_YEAR;
  readonly payload: GroupFiscalYearResponse;
};

export type FailFetchGroupFiscalYearAction = {
  readonly type: typeof FAIL_FETCH_GROUP_FISCAL_YEAR;
  readonly payload: ErrorType;
};

export type FetchPresenceOrAbsenceOfSalesListStartAction = {
  readonly type: typeof FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START;
};
export type FetchPresenceOrAbsenceOfSalesListSuccessAction = {
  readonly type: typeof FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS;
  readonly payload: PresenceOrAbsenceOfSalesListResponse;
};
export type FetchPresenceOrAbsenceOfSalesListFailAction = {
  readonly type: typeof FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL;
  readonly payload: ErrorType;
};
export type FetchPresenceOrAbsenceOfSalesListEndAction = {
  readonly type: typeof FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END;
};
export type ClearRealtimeStoresAction = {
  readonly type: typeof CLEAR_REALTIME_STORES;
};

export type ChangeSelectBannerTypeAction = {
  readonly type: typeof CHANGE_SELECT_BANNER_TYPE;
  readonly payload: BANNER_TYPE;
};

export type ClearBannerTypeAction = {
  readonly type: typeof CLEAR_BANNER_TYPE;
};

export type ChangeAssignedStore = {
  readonly type: typeof CHANGE_ASSIGNED_STORE;
  readonly payload: boolean;
};

export type TaxSettingStatusStartAction = {
  readonly type: typeof FETCH_TAX_SETTING_STATUS_START;
};
export type TaxSettingStatusSuccessAction = {
  readonly type: typeof FETCH_TAX_SETTING_STATUS_SUCCESS;
  readonly payload: TaxSettingStatus;
};
export type TaxSettingStatusFailAction = {
  readonly type: typeof FETCH_TAX_SETTING_STATUS_FAIL;
  readonly payload: ErrorType;
};

export type ClearTaxSettingStatusAction = {
  readonly type: typeof CLEAR_TAX_SETTING_STATUS;
};

// Action Creators
export type Action =
  | UserFetchStoresStartAction
  | FetchRealtimeStoresStartAction
  | FetchRealtimeStoresSuccessAction
  | FetchRealtimeStoresFailAction
  | ClearStateStoresAction
  | SelectStoreAction
  | SetSelectedStoreAction
  | ClickShowMoreAction
  | ClearStateStoreAction
  | ResetStateStoresAction
  | ChangeSelectStoreAction
  | ResetSelectedStoreFlagAction
  | ChangeDisplayedStoreListAction
  | ChangeSelecteIndicesTypeAction
  | StartFetchStoreSalesCustomizeItemAction
  | SuccessStoreSalesCustomizeItemAction
  | FailStoreSalesCustomizeItemAction
  | StartFetchSalesCustomizeItemAction
  | SuccessSalesCustomizeItemAction
  | FailSalesCustomizeItemAction
  | ChangeSalesCustomizeConfigModalStateAction
  | ResetSalesCustomizeConfigModalState
  | StartPostSalesCustomizeConfigAction
  | SuccessPostSalesCustomizeConfigAction
  | FailurePostSalesCustomizeConfigAction
  | EditConfigAction
  | ChangeConfigDialogStateAction
  | StartPostFiscalYearSettingsAction
  | ChangeFiscalYearSettingsModalStateAction
  | CloseFiscalYearSettingsModalAction
  | StartFetchGroupFiscalYearAction
  | SuccessFetchGroupFiscalYearAction
  | FailFetchGroupFiscalYearAction
  | FetchPresenceOrAbsenceOfSalesListStartAction
  | FetchPresenceOrAbsenceOfSalesListSuccessAction
  | FetchPresenceOrAbsenceOfSalesListFailAction
  | FetchPresenceOrAbsenceOfSalesListEndAction
  | ClearRealtimeStoresAction
  | ChangeSelectBannerTypeAction
  | ClearBannerTypeAction
  | ChangeAssignedStore
  | TaxSettingStatusStartAction
  | TaxSettingStatusSuccessAction
  | TaxSettingStatusFailAction
  | ClearTaxSettingStatusAction;

export const userFetchStoresStart = (): UserFetchStoresStartAction => {
  return {
    type: USER_FETCH_STORES_START,
  };
};
export const fetchRealtimeStoresStart = (): FetchRealtimeStoresStartAction => {
  return {
    type: FETCH_REALTIME_STORES_START,
  };
};
export const fetchRealtimeStoresSuccess = (
  stores: RealtimeSummariesResponse
): FetchRealtimeStoresSuccessAction => {
  return {
    type: FETCH_REALTIME_STORES_SUCCESS,
    payload: stores,
  };
};
export const fetchRealtimeStoresFail = (error: ErrorType): FetchRealtimeStoresFailAction => {
  return {
    type: FETCH_REALTIME_STORES_FAIL,
    payload: error,
  };
};
export const clearStateStores = (): ClearStateStoresAction => {
  return {
    type: CLEAR_STATE_STORES,
  };
};
export const selectStore = (akrCode: AkrCode.T, log?: Logger): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: akrCode,
    meta: log && log.tracker,
  };
}; // storeコンテナ以外から呼ぶときはこのメソッド（API再fetchの必要がないので)
// 店舗変更につきapiの再度呼び出しが必要になるときはselectStore()を使うこと.

export const setSelectedStore = (akrCode?: string | null): SetSelectedStoreAction => {
  return {
    type: SET_SELECTED_STORE,
    payload: akrCode,
  };
};
export const clearStateStore = (): ClearStateStoreAction => {
  return {
    type: CLEAR_STATE_STORE,
  };
};
export const resetStateStores = (selectedAkrCode: string): ResetStateStoresAction => {
  return {
    type: RESET_STATE_STORES,
    payload: selectedAkrCode,
  };
};
export const changeSelectStore = (akrCode: Set<string>): ChangeSelectStoreAction => {
  return {
    type: CHANGE_SELECT_STORE,
    payload: akrCode,
  };
};
export const resetSelectedStoreFlag = (): ResetSelectedStoreFlagAction => {
  return {
    type: RESET_SELECTED_STORE_FLAG,
  };
};
export const changeDisplayedStoreList = (isDisplayed: boolean): ChangeDisplayedStoreListAction => {
  return {
    type: CHANGE_DISPLAYED_STORE_LIST,
    payload: isDisplayed,
  };
};
export const changeSelectIndicesType = (
  selectedIndicesType: keyof typeof INDICES_TYPE
): ChangeSelecteIndicesTypeAction => {
  return {
    type: CHANGE_SELECTE_INDICES_TYPE,
    payload: selectedIndicesType,
  };
};

export const startFetchStoreSalesCustomizeItem = (
  akrCode: AkrCode.T
): StartFetchStoreSalesCustomizeItemAction => {
  return {
    type: START_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
    payload: { akrCode },
  };
};

export const successStoreSalesCustomizeItem = (
  data: SalesCustomizeItemList
): SuccessStoreSalesCustomizeItemAction => {
  return {
    type: SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
    payload: data,
  };
};

export const failStoreSalesCustomizeItem = (err: ErrorType): FailStoreSalesCustomizeItemAction => {
  return {
    type: FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM,
    payload: err,
  };
};

export const startFetchSalesCustomizeItem = (): StartFetchSalesCustomizeItemAction => {
  return {
    type: START_FETCH_SALES_CUSTOMIZE_ITEM,
  };
};

export const successSalesCustomizeItem = (data: SalesCustomizeItemList): SuccessSalesCustomizeItemAction => {
  return {
    type: SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM,
    payload: data,
  };
};

export const failSalesCustomizeItem = (err: ErrorType): FailSalesCustomizeItemAction => {
  return {
    type: FAIL_FETCH_SALES_CUSTOMIZE_ITEM,
    payload: err,
  };
};

export const changeSalesCustomizeConfigModalState = (): ChangeSalesCustomizeConfigModalStateAction => {
  return {
    type: CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE,
  };
};

export const resetSalesCustomizeConfigModalState = (): ResetSalesCustomizeConfigModalState => {
  return {
    type: RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE,
  };
};

export const startPostSalesCustomizeConfig = (
  config: SalesCustomizeConfig
): StartPostSalesCustomizeConfigAction => {
  return {
    type: START_POST_SALES_CUSTOMIZE_CONFIG,
    payload: config,
  };
};

export const successPostSalesCustomizeConfig = (): SuccessPostSalesCustomizeConfigAction => {
  return {
    type: SUCCESS_POST_SALES_CUSTOMIZE_CONFIG,
  };
};

export const failurePostSalesCustomizeConfig = (error: ErrorType): FailurePostSalesCustomizeConfigAction => {
  return {
    type: FAILURE_POST_SALES_CUSTOMIZE_CONFIG,
    payload: error,
  };
};

export const editConfig = (): EditConfigAction => {
  return {
    type: EDIT_CONFIG,
  };
};

export const changeConfigDialogState = (): ChangeConfigDialogStateAction => {
  return {
    type: CHANGE_CONFIG_DIALOG_STATE,
  };
};

export const startPostFiscalYearSettings = (startMonth: number): StartPostFiscalYearSettingsAction => {
  return {
    type: START_POST_FISCAL_YEAR_SETTINGS,
    payload: startMonth,
  };
};

export const changeFiscalYearSettingsModalState = (): ChangeFiscalYearSettingsModalStateAction => {
  return {
    type: CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE,
  };
};

export const closeFiscalYearSettingsMoal = (): CloseFiscalYearSettingsModalAction => {
  return {
    type: CLOSE_FISCAL_YEAR_SETTINGS_MODAL,
  };
};

export const startFetchGroupFiscalYear = (): StartFetchGroupFiscalYearAction => {
  return {
    type: START_FETCH_GROUP_FISCAL_YEAR,
  };
};

export const successFetchGroupFiscalYear = (
  response: GroupFiscalYearResponse
): SuccessFetchGroupFiscalYearAction => {
  return {
    type: SUCCESS_FETCH_GROUP_FISCAL_YEAR,
    payload: response,
  };
};

export const failFetchGroupFiscalYear = (error: ErrorType): FailFetchGroupFiscalYearAction => {
  return {
    type: FAIL_FETCH_GROUP_FISCAL_YEAR,
    payload: error,
  };
};

export const fetchPresenceOrAbsenceOfSalesListStart = (): FetchPresenceOrAbsenceOfSalesListStartAction => {
  return {
    type: FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START,
  };
};
export const fetchPresenceOrAbsenceOfSalesListSuccess = (
  response: PresenceOrAbsenceOfSalesListResponse
): FetchPresenceOrAbsenceOfSalesListSuccessAction => {
  return {
    type: FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS,
    payload: response,
  };
};
export const fetchPresenceOrAbsenceOfSalesListFail = (
  error: ErrorType
): FetchPresenceOrAbsenceOfSalesListFailAction => {
  return {
    type: FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL,
    payload: error,
  };
};
export const fetchPresenceOrAbsenceOfSalesListEnd = (): FetchPresenceOrAbsenceOfSalesListEndAction => {
  return {
    type: FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END,
  };
};
export const clearRealtimeStores = (): ClearRealtimeStoresAction => {
  return {
    type: CLEAR_REALTIME_STORES,
  };
};

export const changeSelectBannerType = (bannerType: BANNER_TYPE): ChangeSelectBannerTypeAction => {
  return {
    type: CHANGE_SELECT_BANNER_TYPE,
    payload: bannerType,
  };
};

export const clearBannerType = (): ClearBannerTypeAction => {
  return {
    type: CLEAR_BANNER_TYPE,
  };
};

export const changeAssignedStore = (boolean: boolean): ChangeAssignedStore => ({
  type: CHANGE_ASSIGNED_STORE,
  payload: boolean,
});

export const fetchTaxSettingStatusStart = (): TaxSettingStatusStartAction => {
  return {
    type: FETCH_TAX_SETTING_STATUS_START,
  };
};
export const fetchTaxSettingStatusSuccess = (data: TaxSettingStatus): TaxSettingStatusSuccessAction => {
  return {
    type: FETCH_TAX_SETTING_STATUS_SUCCESS,
    payload: data,
  };
};
export const fetchTaxSettingStatusFail = (error: ErrorType): TaxSettingStatusFailAction => {
  return {
    type: FETCH_TAX_SETTING_STATUS_FAIL,
    payload: error,
  };
};

export const clearTaxSettingStatus = (): ClearTaxSettingStatusAction => {
  return {
    type: CLEAR_TAX_SETTING_STATUS,
  };
};

export const actions = {
  userFetchStoresStart,
  fetchRealtimeStoresStart,
  fetchRealtimeStoresSuccess,
  fetchRealtimeStoresFail,
  clearStateStores,
  selectStore,
  setSelectedStore,
  clearStateStore,
  resetStateStores,
  changeSelectStore,
  resetSelectedStoreFlag,
  changeDisplayedStoreList,
  changeSelectIndicesType,
  changeSalesCustomizeConfigModalState,
  startFetchStoreSalesCustomizeItem,
  successStoreSalesCustomizeItem,
  failStoreSalesCustomizeItem,
  startFetchSalesCustomizeItem,
  successSalesCustomizeItem,
  failSalesCustomizeItem,
  resetSalesCustomizeConfigModalState,
  startPostSalesCustomizeConfig,
  successPostSalesCustomizeConfig,
  failurePostSalesCustomizeConfig,
  editConfig,
  changeConfigDialogState,
  startPostFiscalYearSettings,
  changeFiscalYearSettingsModalState,
  closeFiscalYearSettingsMoal,
  startFetchGroupFiscalYear,
  successFetchGroupFiscalYear,
  failFetchGroupFiscalYear,
  fetchPresenceOrAbsenceOfSalesListStart,
  fetchPresenceOrAbsenceOfSalesListSuccess,
  fetchPresenceOrAbsenceOfSalesListFail,
  fetchPresenceOrAbsenceOfSalesListEnd,
  clearRealtimeStores,
  changeSelectBannerType,
  clearBannerType,
  changeAssignedStore,
  fetchTaxSettingStatusStart,
  fetchTaxSettingStatusSuccess,
  fetchTaxSettingStatusFail,
  clearTaxSettingStatus,
};

export const initialState: State = {
  loading: false,
  loaded: false,
  realTimeLoading: false,
  realTimeLoaded: false,
  isDisplayedStoreList: false,
  realTimeData: {
    allCount: 0,
    responseDateTime: '',
    rbApiStatus: 'available',
    regiApiStatus: 'available',
    summaries: [],
  },
  selectedStores: new Set(),
  isChangeSelectedStores: false,
  // 過去に選択したstore tagを3つだけ記録しておく.
  selectedAkrCode: undefined,
  error: undefined,
  selectedIndicesType: 'daily',
  selectedBannerType: BANNER_TYPE_NONE,
  storeSalesCustomizeItem: apiState.initial(),
  salesCustomizeItem: apiState.initial(),
  isOpenSalesCustomizeConfigModal: false,
  isEditingConfig: false,
  isOpenConfigDialog: false,
  postSalesCustomizeConfig: apiState.initial(),
  isOpenFiscalYearSettingsModal: false,
  fetchGroupFiscalYearInfoState: apiState.initial(),
  presenceOrAbsenceOfSalesListState: apiState.initial(),
  isChangeAssignedStoreFlag: false,
  taxSettingStatus: apiState.initial(),
};

// reducer
const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case FETCH_REALTIME_STORES_START:
      return { ...state, realTimeLoading: true };

    case FETCH_REALTIME_STORES_SUCCESS:
      return {
        ...state,
        realTimeData: action.payload,
        realTimeLoading: false,
        realTimeLoaded: true,
        error: undefined,
      };

    case FETCH_REALTIME_STORES_FAIL:
      return { ...state, error: action.payload, realTimeLoading: false };

    case CLEAR_STATE_STORES:
      return initialState;

    case SET_SELECTED_STORE:
      return { ...state, selectedAkrCode: action.payload };

    case CLEAR_STATE_STORE:
      // CLEAR_STATE_STORESと同じ処理？
      return initialState;

    case RESET_STATE_STORES:
      return { ...initialState, selectedStores: state.selectedStores, selectedAkrCode: action.payload };

    case CHANGE_SELECT_STORE:
      return { ...state, selectedStores: action.payload, isChangeSelectedStores: true };

    case RESET_SELECTED_STORE_FLAG:
      return { ...state, isChangeSelectedStores: false };

    case CHANGE_DISPLAYED_STORE_LIST:
      return { ...state, isDisplayedStoreList: action.payload };

    case CHANGE_SELECTE_INDICES_TYPE:
      return { ...state, selectedIndicesType: action.payload };

    case SUCCESS_FETCH_STORE_SALES_CUSTOMIZE_ITEM:
      return { ...state, storeSalesCustomizeItem: apiState.completed(action.payload) };

    case FAIL_FETCH_STORE_SALES_CUSTOMIZE_ITEM:
      return { ...state, storeSalesCustomizeItem: apiState.failed(action.payload) };

    case SUCCESS_FETCH_SALES_CUSTOMIZE_ITEM:
      return { ...state, salesCustomizeItem: apiState.completed(action.payload) };

    case FAIL_FETCH_SALES_CUSTOMIZE_ITEM:
      return { ...state, salesCustomizeItem: apiState.failed(action.payload) };

    case CHANGE_SALES_CUSTOMIZE_CONFIG_MODAL_STATE:
      return { ...state, isOpenSalesCustomizeConfigModal: !state.isOpenSalesCustomizeConfigModal };

    case RESET_SALES_CUSTOMIZE_CONFIG_MODAL_STATE:
      return {
        ...state,
        isOpenSalesCustomizeConfigModal: false,
        isOpenConfigDialog: false,
        isEditingConfig: false,
      };

    case START_POST_SALES_CUSTOMIZE_CONFIG:
      return { ...state, postSalesCustomizeConfig: apiState.started() };

    case SUCCESS_POST_SALES_CUSTOMIZE_CONFIG:
      return { ...state, postSalesCustomizeConfig: apiState.completed({}) };

    case FAILURE_POST_SALES_CUSTOMIZE_CONFIG:
      return { ...state, postSalesCustomizeConfig: apiState.failed(action.payload) };

    case EDIT_CONFIG:
      return { ...state, isEditingConfig: true };

    case CHANGE_CONFIG_DIALOG_STATE:
      return { ...state, isOpenConfigDialog: !state.isOpenConfigDialog };

    case START_POST_FISCAL_YEAR_SETTINGS:
      return state;

    case CHANGE_FISCAL_YEAR_SETTINGS_MODAL_STATE:
      return { ...state, isOpenFiscalYearSettingsModal: !state.isOpenFiscalYearSettingsModal };

    case CLOSE_FISCAL_YEAR_SETTINGS_MODAL:
      return { ...state, isOpenFiscalYearSettingsModal: false };

    case START_FETCH_GROUP_FISCAL_YEAR:
      return state;

    case SUCCESS_FETCH_GROUP_FISCAL_YEAR:
      return { ...state, fetchGroupFiscalYearInfoState: apiState.completed(action.payload) };

    case FAIL_FETCH_GROUP_FISCAL_YEAR:
      return { ...state, fetchGroupFiscalYearInfoState: apiState.failed(action.payload) };

    case FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_START:
      return state;

    case FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_SUCCESS:
      return { ...state, presenceOrAbsenceOfSalesListState: apiState.completed(action.payload) };

    case FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_FAIL:
      return { ...state, presenceOrAbsenceOfSalesListState: apiState.failed(action.payload) };

    case FETCH_PRESENCE_OR_ABSENCE_SALES_LIST_END:
      return state;

    case CLEAR_REALTIME_STORES:
      return { ...state, realTimeLoaded: false };

    case CHANGE_SELECT_BANNER_TYPE:
      return { ...state, selectedBannerType: action.payload };

    case CLEAR_BANNER_TYPE:
      return { ...state, selectedBannerType: BANNER_TYPE_NONE };

    case CHANGE_ASSIGNED_STORE:
      return { ...state, isChangeAssignedStoreFlag: action.payload };

    case FETCH_TAX_SETTING_STATUS_START:
      return state;

    case FETCH_TAX_SETTING_STATUS_SUCCESS:
      return { ...state, taxSettingStatus: apiState.completed(action.payload) };

    case FETCH_TAX_SETTING_STATUS_FAIL:
      return { ...state, taxSettingStatus: apiState.failed(action.payload) };

    case CLEAR_TAX_SETTING_STATUS:
      return { ...state, taxSettingStatus: apiState.initial() };

    default:
      return state;
  }
};

export default reducer;
