import _ from 'lodash';
import { PlfGrant } from '../typedef/PlfGrant';
import { StoresData } from '../modules/user';
import Big from 'big.js';
import { ErrorType, ErrorData } from '../typedef/api/Utility';
import { MclDayjs, mclDayjs } from './mclDate';
const big = Big();
big.RM = 0; // 切り捨て

// uaからデバイスの種類を判断する関数
export const _getDivice = (): 'sp' | 'tab' | 'pc' => {
  const userAgentData = navigator.userAgentData;
  if (userAgentData != null) {
    return userAgentData.mobile ? 'sp' : 'pc';
  } else {
    const ua = navigator.userAgent;
    if (
      ua.indexOf('iPhone') > 0 ||
      ua.indexOf('iPod') > 0 ||
      (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
    ) {
      return 'sp';
    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
      return 'tab';
    } else {
      return 'pc';
    }
  }
};

// UserAgentからMobileTypeを取得 スマートフォンであることの確認
export const getMobileType = (): 'iphone' | 'android' | 'other' => {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0) {
    return 'iphone';
  } else if (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
    return 'android';
  } else {
    return 'other';
  }
};

/**
 * @returns 使用しているブラウザを返す
 */
export const getBrowserType = ():
  | 'Safari'
  | 'Firefox'
  | 'Internet Explorer'
  | 'Microsoft Edge'
  | 'Google Chrome'
  | 'Opera'
  | null => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
    return 'Internet Explorer';
  } else if (userAgent.indexOf('edge') != -1 || userAgent.indexOf('edg') != -1) {
    return 'Microsoft Edge';
  } else if (userAgent.indexOf('opera') != -1 || userAgent.indexOf('opr') != -1) {
    return 'Opera';
  } else if (userAgent.indexOf('chrome') != -1) {
    return 'Google Chrome';
  } else if (userAgent.indexOf('safari') != -1) {
    return 'Safari';
  } else if (userAgent.indexOf('firefox') != -1) {
    return 'Firefox';
  } else {
    return null;
  }
};

/**
 * 使用しているブラウザをvoc収集用に変換
 * @returns ブラウザとバージョンを返す
 */
export const getVocBrowser = (): {
  browser: 'Edge' | 'Chrome' | 'Safari' | 'Firefox' | 'other';
  version: string | 'other' | null;
} => {
  const browserType = getBrowserType();
  // UA-CHでブラウザバージョンを取得、UA-CHに対応しているedgeとchrome(とopera)のみ
  const getVersion = (browser: 'Microsoft Edge' | 'Google Chrome'): string | null => {
    const { brands } = navigator.userAgentData;
    if (brands.length > 0) {
      const brand = brands.find(brand => brand.brand === browser);
      if (brand != null) {
        return brand.version;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  switch (browserType) {
    case 'Google Chrome':
      return { browser: 'Chrome', version: getVersion(browserType) };
    case 'Microsoft Edge':
      return { browser: 'Edge', version: getVersion(browserType) };
    case 'Safari':
      return { browser: 'Safari', version: null };
    case 'Firefox':
      return { browser: 'Firefox', version: null };
    // 上記以外のブラウザはother
    default:
      return { browser: 'other', version: 'other' };
  }
};

export const isAccessFromPC = () => {
  return _getDivice() === 'pc' ? true : false;
};
// akrCodeをキーにデータの配列を結合する関数

export function joinStoreByAkrcode<
  T extends {
    readonly akrCode: string;
  },
  U extends {
    readonly akrCode: string;
  }
>(storeObjArray1: ReadonlyArray<T>, storeObjArray2: ReadonlyArray<U>): ReadonlyArray<T & U> {
  const result = {};
  storeObjArray1.map(soa1 => (result[soa1.akrCode] = soa1 || []));
  const mergedArray = storeObjArray2.map(soa2 => {
    const tmp: T = Object.assign({}, result[soa2.akrCode]);
    return _.merge(tmp, soa2);
  });

  return mergedArray;
}

// COMMENT: なぜNumber.isInteger()を使わない？
export const isInteger = (x: number) => {
  return Math.round(x) === x;
};

// COMMENT: 存在意義がよくわからない
// 小数点の桁数を判定する
export const checkPresition = (num: number, target: number) => {
  const array = num.toString().match(/^-?[0-9]+\.[0-9]+$/) ? num.toString().split('.') : [num, ''];

  if ((array[1] as string).length === target) {
    return true;
  } else {
    return false;
  }
};

// 引数plfGrantの値が管理者か判定する関数
export const isCOO = (plfGrant: PlfGrant) => {
  if (plfGrant === '01') {
    return true;
  } else {
    return false;
  }
};

/**
 * batchが落ちているかどうかを判定する関数
 * @param end 
 * @param now 
 * @returns true:バッチ遅延中/false:正常
 */
export const isBatchFailed = (end: string, now: MclDayjs) => {
  const nowHour = now.hour();
  const batchEndDay = mclDayjs(end).hour(0).minute(0).second(0);
  const dateDiff = now.diff(batchEndDay, 'day');
  const span = Math.abs(dateDiff);

  if (span >= 2) {
    return true;
  } else if (span >= 1 && nowHour >= 10) {
    return true;
  } else {
    return false;
  }
};

/**
 * storesの中からakrCodeにマッチする店舗名を返す
 * @param {$ReadOnlyArray<StoresData>} stores
 * @param {string} akrCode
 */
export const getStoreName = (stores: ReadonlyArray<StoresData>, akrCode: string): string => {
  const store = stores.find(store => {
    return store.akrCode === akrCode;
  });
  return store != null ? store.storeName : '';
};

const env = process.env.REACT_APP_ENV;

/**
 * 現在の環境を返す
 * @returns {string | undefined} env
 */
export const getEnv = (): string | undefined => {
  return env;
};

const prodAssert = (condition: boolean, message: string) => {
  if (!condition) {
    console.error('Assertion failed: ' + message);
  }
};

const devAssert = (condition: boolean, message: string) => {
  if (!condition) {
    throw new Error('Assertion failed: ' + message);
  }
};

export const assert = ['production', 'staging'].includes(env as string) ? prodAssert : devAssert;

export const assertNotNull = <T extends any>(value?: T | null, message?: string): T => {
  assert(value != null, message == null ? "Argument can't be null" : message);
  return value as any;
};

export const assertUnreachable = (): never => {
  assert(false, 'This statement should be unreachable');
  return undefined as never;
};

export const assertNever = (x: never): never => {
  assert(false, 'Unexpected object' + x);
  return undefined as never;
};

// flowは===の左辺と右辺の型が異なっても型エラーとしない
/**
 * @deprecated deprecated since flow is obsoleted, do not use
 */
export const eqb = (o1: boolean, o2: boolean): boolean => o1 === o2;

/**
 * @deprecated deprecated since flow is obsoleted, do not use
 */
export const eqs = (o1: string, o2: string): boolean => o1 === o2;

/**
 * @deprecated deprecated since flow is obsoleted, do not use
 */
export const eqn = (o1: number, o2: number): boolean => o1 === o2;

export const ignore0 =
  <U extends any>(f: () => U): (() => void) =>
  () => {
    f();
  };

export const ignore1 =
  <T extends any, U>(f: (a: T) => U): ((a: T) => void) =>
  (t: T) => {
    f(t);
  };

export const ignore2 =
  <T1 extends any, T2, U>(f: (b: T1, a: T2) => U): ((b: T1, a: T2) => void) =>
  (t1: T1, t2: T2) => {
    f(t1, t2);
  };

export const pipe1 = <T1 extends any, T2>(arg: T1, f: (a: T1) => T2): T2 => f(arg);

export const pipe2 = <T1 extends any, T2, T3>(arg: T1, f1: (a: T1) => T2, f2: (a: T2) => T3): T3 =>
  f2(f1(arg));

export const pipe3 = <T1 extends any, T2, T3, T4>(
  arg: T1,
  f1: (a: T1) => T2,
  f2: (a: T2) => T3,
  f3: (a: T3) => T4
): T4 => f3(f2(f1(arg)));

export const pipe4 = <T1 extends any, T2, T3, T4, T5>(
  arg: T1,
  f1: (a: T1) => T2,
  f2: (a: T2) => T3,
  f3: (a: T3) => T4,
  f4: (a: T4) => T5
): T5 => f4(f3(f2(f1(arg))));

export const pipe5 = <T1 extends any, T2, T3, T4, T5, T6>(
  arg: T1,
  f1: (a: T1) => T2,
  f2: (a: T2) => T3,
  f3: (a: T3) => T4,
  f4: (a: T4) => T5,
  f5: (a: T5) => T6
): T6 => f5(f4(f3(f2(f1(arg))))); // Array.prototype.flatもLodashの_.flattenも型が頭おかしい

export const flatten = <T extends any>(arrayOfArray: ReadonlyArray<ReadonlyArray<T>>): ReadonlyArray<T> =>
  arrayOfArray.reduce((acc, val) => acc.concat(val), []);

export const zip = <T1 extends any, T2>(
  array1: ReadonlyArray<T1>,
  array2: ReadonlyArray<T2>
): ReadonlyArray<[T1, T2]> => {
  assert(array1.length === array2.length, 'The arrays have different sizes');
  const array: Array<[T1, T2]> = [];

  for (let i = 0; i < array1.length; i++) {
    array.push([array1[i], array2[i]]);
  }

  return array;
};

// 人はなぜ
export const flatMap = <T extends any, U>(
  array: ReadonlyArray<T>,
  mapper: (a: T) => ReadonlyArray<U>
): ReadonlyArray<U> => array.map(mapper).reduce((acc, val) => acc.concat(val), []);

export const safeDivide = (
  numerator: number | string | Big,
  denominator: number | string | Big
): Big | undefined => {
  try {
    return big(numerator).div(denominator);
  } catch (e) {
    return undefined;
  }
};

export const getErrorData = (error: ErrorType | null | undefined): ErrorData | null => {
  if (error == null) {
    return null;
  } else if ('response' in error && error.response != null) {
    return error.response?.data;
  } else if ('data' in error) {
    return error.data;
  } else {
    return null;
  }
};

export const getReturnCode = (error: ErrorType | null | undefined): string | null => {
  if (error == null) {
    return null;
  } else if ('response' in error && error.response != null) {
    return error.response?.data.returnCode;
  } else if ('data' in error) {
    return error.data.returnCode;
  } else {
    return null;
  }
};

/**
 * csv出力のレスポンスからファイル名を取得する
 * @param request APIで返ってきたrequestのオブジェクト
 * @param defaultFileName ファイル名が取得できない場合のデフォルト名
 * @returns ファイル名
 */
export const getCsvFileName = (request: XMLHttpRequest, defaultFileName: string): string => {
  const content = request.getResponseHeader('Content-Disposition');
  if (content == null) {
    return defaultFileName;
  }
  const fileNameFromContent = content.match(/filename="(.*)"/)?.pop();
  return fileNameFromContent != null && fileNameFromContent !== '' ? fileNameFromContent : defaultFileName;
};

export const getIpadWidth = (): number => {
  return 1180;
};

/**
 * @param { 'all' | 'manager' } laborCostViewScopeType 基本設定画面で設定された閲覧範囲
 * @param { PlfGrant } plfGrant 管理者権限 "01"=あり "02"=なし
 * @returns { boolean } 人件費管理権限の設定が管理者のみ かつ 管理者では無い場合true それ以外はfalseを返す
 */
export const isLaborCostNoAuthority = (
  laborCostViewScopeType: 'all' | 'manager',
  plfGrant: PlfGrant | undefined
): boolean => {
  if (laborCostViewScopeType === 'all') {
    return false;
  } else {
    if (plfGrant != null) {
      return laborCostViewScopeType === 'manager' && !isCOO(plfGrant);
    } else {
      return true;
    }
  }
};
