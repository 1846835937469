/**
 * Storeページ内のヘッダ
 * 店名、店舗変更ボタン、設定ボタン
 */
import React from 'react';
import styled from 'styled-components';
import LatestDateLabel from '../../../../../components/common/molecules/LastUpdatedDateLabel';
import { StoresData, UserData } from '../../../../../modules/user';
import StorechangeButton from './StorechangeButton';
import * as AkrCode from '../../../../../typedef/AkrCode';
import SettingButton from './SettingButton';
import { INDICES_TYPE } from '../../storesConstants';
import TaxNotice from './banner/TaxNotice';
import { track } from '../../../../../modules/logging';
import { ApiState, API_STATE_COMPLETED } from '../../../../../typedef/api/Utility';
import { TaxSettingStatus } from '../../../../../typedef/api/StoreIndices';
import { BasicSetting as BasicSettingType } from '../../../../../typedef/api/BasicSetting';

type Props = {
  readonly selectedStore?: StoresData | undefined;
  readonly isDisplayedStoreList: boolean;
  readonly isSelectedThisRange: boolean;
  readonly isMonthlyIndices: boolean;
  readonly storeData: ReadonlyArray<{
    storeName: string;
    salesGoalRate?: number | string | undefined;
    akrCode: AkrCode.T;
  }>;
  readonly changeDisplayedStoreList: (isDisplay: boolean) => void;
  readonly handleClickStoreListItem: (a: {
    readonly akrCode: AkrCode.T;
    readonly storeName?: string;
  }) => void;
  readonly handleClickFiscalYearSettingsMoalOpener: () => void;
  readonly handleClickSetting: () => void;
  readonly lastUpdateDate?: string;
  readonly onClickTooltip: () => void;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly tracker: typeof track;
  readonly taxSettingStatus: ApiState<TaxSettingStatus>;
  readonly basicSetting: ApiState<BasicSettingType>;
  readonly userData: UserData | null;
};
const StoreHead = ({
  selectedStore,
  isDisplayedStoreList,
  changeDisplayedStoreList,
  storeData,
  isSelectedThisRange,
  isMonthlyIndices,
  handleClickStoreListItem,
  handleClickFiscalYearSettingsMoalOpener,
  handleClickSetting,
  lastUpdateDate,
  onClickTooltip,
  selectedIndicesType,
  tracker,
  taxSettingStatus,
  basicSetting,
  userData,
}: Props) => {
  return (
    <React.Fragment>
      <TabContainer hasStoreList={isDisplayedStoreList}>
        <StoreSettingContainer>
          <StoreContainer>
            {selectedStore != null && <StoreTitle>{selectedStore.storeName}</StoreTitle>}
            {!isDisplayedStoreList && storeData.length > 1 && selectedStore != null && (
              <StorechangeButton
                options={storeData}
                headerLeftWord="店舗を選択"
                headerRightWord={isMonthlyIndices ? '本年度目標達成率' : '当月目標達成率'}
                isSelectedThisRange={isSelectedThisRange}
                handleClickStoreListItem={handleClickStoreListItem}
                selectedAkrCode={selectedStore.akrCode}
                selectedStoreName={selectedStore.storeName}
              />
            )}
          </StoreContainer>
          <SettingContainer>
            <SettingButton
              isDisplayedStoreList={isDisplayedStoreList}
              changeDisplayedStoreList={changeDisplayedStoreList}
              handleClickFiscalYearSettingsMoalOpener={handleClickFiscalYearSettingsMoalOpener}
              handleClickSetting={handleClickSetting}
            />
          </SettingContainer>
        </StoreSettingContainer>
        {lastUpdateDate != null && (
          <StyledLatestDateLabel
            dateTime={lastUpdateDate}
            onClick={onClickTooltip}
            viewName="store_indices"
            functionName="lastUpdatedDateLabel"
            feature={selectedIndicesType}
          />
        )}
      </TabContainer>
      {basicSetting.type === API_STATE_COMPLETED && taxSettingStatus.type === API_STATE_COMPLETED && (
        <TaxNotice
          taxSettingStatus={taxSettingStatus}
          basicSetting={basicSetting}
          tracker={tracker}
          userData={userData}
          isDisplayedStoreList={isDisplayedStoreList}
        />
      )}
    </React.Fragment>
  );
};
const TabContainer = styled.div<{ hasStoreList: boolean }>`
  position: relative;
  width: 100%;
  ${props => !props.hasStoreList && 'border-bottom: 1px solid #dddddd;'};
  padding: ${props => (props.hasStoreList ? '24px 0px 16px;' : '22px 0px 16px 24px;')};
  align-items: center;
`;

const StoreSettingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StoreContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SettingContainer = styled.div`
  width: 50px;
  margin: auto 24px auto 10px;
  cursor: pointer;
`;

const StoreTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledLatestDateLabel = styled(LatestDateLabel)`
  margin-left: 0;
  margin-top: 6px;
`;

export default StoreHead;
